import React from "react";

export const Level1a = (props) => (
    <svg version="1.1" id="Layer_1"
         height={`${props.size}px`}
         width={`${props.size}px`}
         focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve"
         style={{position: 'relative', bottom:'2px'}}>

        <g>
            <path className="st0" d="M10,1.3c-4.8,0-8.8,3.9-8.8,8.7c0,1.6,0.4,3,1.2,4.3c0.8,1.4,2,2.5,3.4,3.3c1.3,0.7,2.8,1.1,4.3,1.1
		c1.5,0,3-0.4,4.2-1.1c1.4-0.8,2.6-1.9,3.4-3.3c0.8-1.3,1.2-2.8,1.2-4.4C18.8,5.2,14.9,1.3,10,1.3z M8.6,12.3H7.7V9.1H6.7V8.5
		c0.2,0,0.4,0,0.5,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3h0.7V12.3z M12.3,12.3L12,11.3h-1.7
		L10,12.3H9l1.6-4.5h1.1l1.6,4.5H12.3z"/>
            <polygon className="st0" points="11.2,8.7 10.6,10.6 11.7,10.6 11.2,8.7 	"/>
        </g>
    </svg>
);