import React from 'react';
import {useStore} from "../../context";
import Text from '../../assets/strings';
import {useSelector} from "react-redux";
import {selectLanguage} from "../../feature/app/appSlice";


export const ToggleButton = (props) => {
    const lang = useSelector(state => selectLanguage(state));
    return (
        <button className={props.state ? 'remove-medication': 'add-medication'} onClick={props.click}>
            { props.state ? Text[lang].web['remove-medication'] : Text[lang].web['add-medication']}
        </button>
    )
};