import React from "react";
import './Psychiatry.scss';

export const Psychiatry = (props) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}>

        <g>
            <g>
                <path className="st0"
                      d="M211.9,132.2c0,0,0,0.1,0,0.2C211.9,132.3,211.9,132.3,211.9,132.2C211.9,132.3,211.9,132.3,211.9,132.2z"/>
                <path className="st0" d="M211.4,134.2l0.1-2.2l0.9-1.8v2.1c0,0.1,0,0.3-0.1,0.4L211.4,134.2z M211.4,132.1c0,0,0,0.1,0,0.1h0.2
			L211.4,132.1z"/>
            </g>
            <path className="st0" d="M235.2,132c-0.8-11.8-9.1-16.8-14.2-18.9c-2.8-16-15.8-18.7-21.5-19.3C191.6,79.1,178,82,173.9,83.1
		c-7-7.3-16.3-8.5-25-3c-9.6-8.6-21.3-7.5-30.7,2.9c-1.8-0.6-3.8-1-5.8-1.2c-0.6-0.1-1.3,0-1.9,0c-11.1,1.2-15.6,8-17.5,12.2
		c-2.5,0.3-5.8,1.2-9.9,2.5c-8.3,2.7-12.5,10.6-11.7,21.7c-0.1,0.1-0.2,0.2-0.3,0.3c-1,0.7-1.9,1.6-2.8,2.6
		c-0.3,0.3-0.6,0.7-0.9,1.1c-0.1,0.1-0.1,0.2-0.2,0.3c-4.4,5.3-6.4,10.6-5.9,15.7c0.7,7.8,6.8,12.7,10.4,15.1
		c0.6,12.7,8.6,20.9,21.1,21.7c7.4,13.3,20.8,12,26.4,10.9c9.6,8.9,18,6.4,22.3,4.1c4.2,2.8,10.3,4.6,14.8,4.3
		c2.3,0.4,12.1,8.9,15.3,11.7c2.1,1.8,4.2,3.6,5.9,5.1c3.6,2.9,6.9,4.4,10.1,4.4c1.4,0,2.6-0.3,3.8-0.8c3.3-1.6,4.8-4.8,4.8-7.3
		c0-1.2-0.8-2.1-3.5-5c-0.9-1-2.2-2.4-2.9-3.2c0.8-2.3,3.2-9,3.5-10.5l0.4-0.1c22.7,0,26.6-13.7,27.3-19.6c0,0,0.1-0.1,0.3-0.2
		c6.2-3.8,8.7-10.7,7-18.8C231,146.3,235.9,139,235.2,132z M191.9,207.8c-0.2,1-0.9,2.4-2.4,3.1c-2.4,1.1-5.7,0-9.5-3
		c-1.8-1.5-3.8-3.2-5.8-5c-10.9-9.5-15.5-12.7-18.2-12.7c-3.7,0.2-9-1.4-12.3-3.6c-0.3-0.2-0.6-0.3-0.9-0.5
		c-2.5-3.3-3.2-9.2-3.3-11.9c1.3,0.3,2.7,0.4,4.1,0.4c1.8,0,3.8-0.2,5.9-0.6c5.8-1.2,9.7-2.3,12-2.9c-0.1,0.1-0.2,0.3-0.3,0.5
		c-0.3,0.8-3,7.6,0.7,13.8c2.7,4.6,7.9,7.3,15.4,8.3c0.4,0,0.8,0.1,1.2,0.1c0.1,0,0.1,0,0.2,0c1.1,0,2-0.8,2.1-1.9
		c0.1-1.2-0.7-2.2-1.9-2.3l-1-0.1c-6.2-0.8-10.3-2.9-12.3-6.2c-2.7-4.6-0.5-9.8-0.5-10c0,0,0,0,0,0c0.2,0.3,0.4,0.6,0.6,1
		c5.7,9.1,18.8,12.7,23.4,13.7c-0.4,1.4-2.2,6.7-3.4,10.2l-0.3,1.2c-0.1,1.7,1,2.7,4.2,6.1C190.3,206,191.4,207.2,191.9,207.8z
		 M223.9,150.9c1.4,6.4-0.4,11.5-5.1,14.3c-1.3,0.8-2.1,2-2.2,3.3c-0.6,5.9-4.5,15.8-23.3,15.9c-1.2,0-18.3-3.4-24.1-12.5
		c-2.1-3.4-2.6-7.2-1.5-11.3c2.3-7,14.4-12.5,26.6-9.1c12.7,3.5,19.9,9.9,20.2,10.2c0.9,0.8,2.2,0.7,3-0.2c0.8-0.9,0.7-2.2-0.2-3
		c-0.8-0.7-8.4-7.4-21.8-11.1c-15-4.2-28.7,2.9-31.7,12c-0.7,2.4-0.9,4.8-0.7,7c-0.1,0-0.2,0-0.4,0.1c0,0-4.3,1.5-14,3.5
		c-6,1.2-10.8,0.4-14-2.4c-5-4.3-4.7-12-4.7-12c0.1-1.2-0.8-2.1-2-2.2c-1.2-0.1-2.1,0.8-2.2,2c0,0.2-0.2,3.7,1.1,7.6
		c-0.3,0-0.7,0-1.1,0.1c-0.4,0.1-0.7,0.3-1,0.4c-5.3,1.7-9.8,0-12.8-4.8c-4.7-7.7-0.8-15.1-0.6-15.5c0.6-1,0.2-2.3-0.8-2.8
		c-1-0.5-2.3-0.2-2.8,0.8c-0.2,0.4-5.2,9.9,0.7,19.6c3,4.9,7.3,7.4,12.4,7.4c1.7,0,3.4-0.3,5.2-0.8c0.4-0.1,0.9-0.3,1.3-0.5
		c0.4-0.2,0.7-0.4,0.9-0.7c0.9,1.6,2,3.2,3.6,4.5c1,0.9,2.1,1.6,3.3,2.2c0,1.3,0.1,8.7,3.1,14.1c-3.9,1.8-9.6,2.2-16.4-4
		c-1-1-2.5-1.3-3.8-1.1c-4.5,1-15.7,2-21.7-8.8c-0.3-0.5-0.6-0.9-1-1.2c2.2-2.8,5.9-9.1,3.9-17.4c-2-7.8-7.9-12.1-16.2-11.5
		c-1.2,0.1-2,1.1-2,2.2c0.1,1.2,1.1,2,2.2,2c6.2-0.4,10.4,2.6,11.9,8.4c2.2,8.8-3.8,14.7-4,14.9c-0.1,0.1-0.2,0.1-0.2,0.2h0
		c-9.2-1.2-14.7-7.4-15.2-17.4c-0.1-1.4-0.8-2.7-2-3.4c-2.9-1.8-7.8-5.8-8.4-11.8c-0.5-5.1,2.3-10.5,8.2-16.1
		c3.3-2.4,7.1-2.7,11.2-0.6c8.1,4,8.6,12.4,8.7,12.8c0.1,1.1,1,2,2.1,2h0.1c1.1-0.1,2-1,2-2.2c0-0.4-0.8-11.2-11-16.3
		c-3.9-1.9-7.7-2.3-11.3-1.2c-0.2-8.1,3-13.8,8.9-15.7c3.9-1.3,7-2.1,9.2-2.4c1.5-0.2,2.8-1.2,3.5-2.7c1.4-3.2,5-8.6,14-9.5
		c0.4,0,0.8,0,1.2,0c1.7,0.1,3.3,0.5,4.8,1c1.5,0.5,3.3,0.1,4.5-1.2c4.4-4.8,9.2-7.2,13.9-7.2c0.9-1.5,1.7-2.3,1.8-2.5
		c0.8-0.9,2.1-0.9,3-0.2c0.9,0.8,0.9,2.1,0.2,3c0,0-0.2,0.2-0.4,0.4c2.2,0.8,4.4,2.1,6.5,3.9c1.4,1.3,3.5,1.4,5,0.5
		c6.6-4.2,13.2-3.7,18.7,1.4c0,0.1,0.1,0.2,0.2,0.3c0.2,0.4,5.2,8.9,5.2,17.2c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
		c0-5.9-2.1-11.7-3.8-15.4c5.2-1.5,14.4-2.2,20.3,8.7c0.7,1.3,2,2.1,3.5,2.3c5.6,0.5,15.3,3.1,17.5,15.7c0.3,1.5,1.3,2.7,2.7,3.3
		c2.5,1,5.9,2.9,8.4,6.4c1.4,0.5,2.8,1.2,4.2,2.2c1,0.6,1.2,1.9,0.6,2.9c-0.5,0.7-1.4,1.1-2.2,0.9c0.2,0.9,0.3,1.8,0.4,2.9
		c0.6,5.8-4.1,12.4-6.2,15C224,148.3,223.7,149.6,223.9,150.9z"/>
            <g>
                <path className="st0" d="M145.3,149.4c-2.2,1.4-4.8,2.2-7.8,2.2c-3.5,0-7.6-1-12.1-3.1l-0.5-0.3c-6-3.9-10.8-10.9-11.2-19.8
			c-7.1-0.3-12.2-4.5-14-11.5c-2.8-11.1,4.9-18.6,5.2-19c0.8-0.8,2.1-0.8,3,0.1c0.8,0.8,0.8,2.2-0.1,3c-0.3,0.3-6.3,6.1-4.1,14.9
			c1.3,5.2,4.9,8.1,10,8.4c0.3-2.9,1.1-6,2.4-9.2c3.5-8.7,10.6-10.5,15.7-8.5c1.1,0.4,1.6,1.6,1.2,2.7c-0.4,1.1-1.6,1.6-2.7,1.2
			c-3.4-1.3-7.9,0.1-10.3,6.2c-5.3,12.8-0.3,23.3,7.1,28.1c6.8,3.1,12.3,3.5,15.8,1.2c4.8-3.1,4.7-10,4.7-10.3
			c0-1.2,0.9-2.1,2.1-2.1c0,0,0,0,0,0c1.2,0,2.1,0.9,2.1,2.1C151.9,136.5,151.8,145.1,145.3,149.4z"/>
                <path className="st0" d="M137.5,152.1c-3.6,0-7.7-1.1-12.3-3.1l-0.5-0.3c-6.7-4.3-10.9-11.6-11.4-19.7c-7.1-0.5-12.2-4.8-14-11.9
			c-2.8-11.3,5-19.1,5.4-19.4c1-1,2.7-0.9,3.7,0.1c0.5,0.5,0.7,1.2,0.7,1.8c0,0.7-0.3,1.3-0.8,1.8c-0.6,0.6-6,6.2-3.9,14.4
			c1.2,4.8,4.4,7.6,9.1,8c0.3-2.9,1.1-5.9,2.4-8.9c3.7-9,11.1-10.9,16.4-8.8c1.3,0.5,2,2,1.5,3.3c-0.5,1.3-2,2-3.4,1.5
			c-3.1-1.2-7.3,0.1-9.7,5.9c-5.2,12.6-0.3,22.9,6.9,27.5c6.6,3,11.9,3.4,15.3,1.2c4.6-3,4.5-9.8,4.5-9.9c0-0.7,0.3-1.4,0.7-1.8
			c0.5-0.5,1.1-0.8,1.8-0.8c1.4,0,2.6,1.1,2.6,2.6c0,0.4,0.1,9.7-6.8,14.3l0,0C143.3,151.3,140.5,152.1,137.5,152.1z M106.4,97.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-7.8,7.7-5.1,18.5c1.7,6.8,6.6,10.9,13.5,11.2l0.5,0l0,0.5c0.4,8,4.5,15.2,11,19.4l0.5,0.3
			c8.1,3.7,14.7,4,19.4,0.9c6.5-4.3,6.4-13,6.4-13.4c0-0.9-0.7-1.6-1.6-1.6c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1
			c0,0.1,0.1,7.4-5,10.7c-3.7,2.4-9.3,2-16.3-1.2c-7.6-4.9-12.7-15.6-7.3-28.7c2.6-6.4,7.4-7.9,11-6.5c0.8,0.3,1.7-0.1,2.1-0.9
			c0.3-0.8-0.1-1.7-0.9-2.1c-4.9-1.9-11.7-0.1-15.1,8.2c-1.3,3.1-2.1,6.1-2.4,9.1l0,0.5l-0.5,0c-5.4-0.3-9.2-3.4-10.5-8.8
			c-2.2-8.7,3.5-14.7,4.2-15.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.1-0.8-0.4-1.1C107.2,98,106.8,97.9,106.4,97.9z"/>
            </g>
            <g>
                <path className="st0" d="M232.8,128.6c-0.5,0.7-1.4,1.1-2.2,0.9c-0.3-0.1-0.5-0.2-0.7-0.3c-3.4-2.3-6.5-3-8.9-2
			c-3.2,1.2-4.6,4.9-4.9,5.9c0,0,0,0,0,0v0c0,0,0,0,0,0c-1.1,4.5-4.3,9.1-9.5,13.7c-0.4,0.3-0.9,0.5-1.4,0.5c-0.6,0-1.2-0.2-1.6-0.7
			c-0.8-0.9-0.7-2.2,0.2-3c4.4-3.9,7.1-7.6,8.1-11.2c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0
			c0.3-1.4,0.4-2.8,0.2-4.2c-1.3-7.9-11.3-12-11.3-12.1c-1.1-0.4-1.6-1.7-1.2-2.7c0.4-1.1,1.7-1.6,2.7-1.2
			c0.5,0.2,11.1,4.6,13.6,13.7c1-0.9,2.1-1.7,3.5-2.2c2.7-1,5.5-0.9,8.6,0.2c1.4,0.5,2.8,1.2,4.2,2.2
			C233.2,126.3,233.4,127.6,232.8,128.6z"/>
                <path className="st0" d="M205.2,147.8c-0.7,0-1.5-0.3-1.9-0.9c-0.5-0.5-0.7-1.2-0.6-1.9c0-0.7,0.3-1.3,0.9-1.8
			c4.3-3.8,6.9-7.4,7.9-10.9l0-0.2l0.1-0.2c0,0,0-0.1,0-0.1l0.1-0.2c0.3-1.3,0.3-2.6,0.1-3.9c-1.2-7.6-10.9-11.7-11-11.7
			c-1.3-0.5-2-2.1-1.4-3.4c0.3-0.6,0.7-1.1,1.4-1.4c0.6-0.3,1.3-0.3,2,0c0.4,0.2,10.7,4.4,13.6,13.3c0.9-0.8,2-1.4,3.1-1.8
			c2.8-1.1,5.8-1,8.9,0.2c1.4,0.5,2.8,1.3,4.3,2.2c0.6,0.4,1,1,1.1,1.6c0.1,0.7,0,1.4-0.4,1.9c-0.6,0.9-1.7,1.3-2.7,1.1
			c-0.3-0.1-0.6-0.2-0.9-0.4c-3.3-2.2-6.2-2.8-8.5-2c-2.9,1.1-4.2,4.4-4.6,5.5l-0.1,0.2c-1.2,4.6-4.4,9.3-9.6,13.8
			C206.4,147.6,205.8,147.8,205.2,147.8z M212.3,132.7c-1,3.6-3.8,7.5-8.2,11.4c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.1,0.8,0.4,1.1
			c0.6,0.6,1.6,0.7,2.2,0.1c5.1-4.5,8.2-9,9.3-13.4v-0.1l0.1-0.1c0.4-1.2,1.9-4.9,5.2-6.2c2.7-1,5.8-0.3,9.4,2
			c0.2,0.1,0.3,0.2,0.5,0.2c0.6,0.1,1.3-0.1,1.7-0.7l0,0c0.2-0.4,0.3-0.8,0.2-1.2s-0.3-0.8-0.7-1c-1.4-0.9-2.8-1.6-4.1-2.1
			c-2.9-1.1-5.7-1.2-8.2-0.2c-1.2,0.5-2.4,1.2-3.4,2.1l-0.6,0.6l-0.2-0.8c-2.4-8.9-12.8-13.2-13.3-13.4c-0.4-0.2-0.8-0.2-1.2,0
			c-0.4,0.2-0.7,0.5-0.8,0.9c-0.3,0.8,0.1,1.7,0.9,2.1c0.1,0,10.4,4.2,11.7,12.5c0.2,1.4,0.2,2.9-0.2,4.3l0,0.2c0,0.1,0,0.2-0.1,0.2
			c0,0.1,0,0.1,0,0.1L212.3,132.7L212.3,132.7z"/>
            </g>
            <g>
                <path className="st0" d="M199.1,133.8c-0.5,0.3-5.2,2.7-10.4,2.7c-2.1,0-4-0.4-5.7-1.2c-0.9-0.5-1.8-1-2.5-1.7
			c-1.5,2.4-4.7,6.7-9.7,8.2c-1.2,0.4-2.5,0.6-3.7,0.6c-2.4,0-4.8-0.7-7.1-2c-8.4-5.5-9.8-12.7-9.5-21.9c0-1.2,1-2.2,2.2-2
			c1.1,0,2,1,2,2.2c-0.3,8.2,0.8,13.8,7.5,18.2c2.4,1.4,5,1.8,7.4,1c5.1-1.6,8-7.6,8-7.6c0-0.1,0.1-0.1,0.1-0.2
			c-0.7-1.3-1.2-2.8-1.5-4.5c-2.9-15.6,12.6-25.9,13.3-26.3c1-0.6,2.3-0.3,2.9,0.6c0.6,1,0.3,2.3-0.6,2.9
			c-0.6,0.4-13.9,9.2-11.5,22.1c0.6,3.4,2.1,5.6,4.4,6.7c1.1,0.5,2.4,0.8,3.9,0.8c4.2,0,8.3-2.1,8.4-2.2c1-0.5,2.3-0.2,2.8,0.9
			C200.5,132,200.2,133.3,199.1,133.8z"/>
                <path className="st0" d="M167.2,142.9c-2.5,0-4.9-0.7-7.3-2.1c-8.6-5.6-10.1-13-9.7-22.4c0-0.7,0.4-1.5,1-1.9c0.5-0.4,1.1-0.6,1.8-0.5
			c1.4,0.1,2.5,1.3,2.4,2.7c-0.3,8,0.8,13.5,7.2,17.8c2.3,1.3,4.7,1.7,6.9,1c4.7-1.4,7.5-6.9,7.7-7.3c-0.6-1.3-1.1-2.8-1.4-4.4
			c-3-16,12.8-26.4,13.5-26.9c1.2-0.8,2.8-0.4,3.6,0.8c0.8,1.2,0.4,2.8-0.8,3.6c-0.1,0.1-13.6,8.8-11.3,21.5c0.6,3.2,2,5.3,4.2,6.4
			c1,0.5,2.3,0.8,3.7,0.8c4.1,0,8-2.1,8.2-2.1c0.6-0.3,1.3-0.4,2-0.2c0.7,0.2,1.2,0.7,1.5,1.3c0.3,0.6,0.4,1.3,0.2,2
			c-0.2,0.7-0.7,1.2-1.3,1.5l0,0l-0.2-0.4l0.2,0.4c-1.3,0.6-5.7,2.7-10.6,2.7c-2.2,0-4.2-0.4-5.9-1.3c-0.8-0.4-1.5-0.8-2.2-1.4
			c-1.6,2.5-4.9,6.5-9.6,7.9C169.8,142.7,168.5,142.9,167.2,142.9z M152.6,117c-0.4,0-0.7,0.2-0.9,0.3c-0.4,0.3-0.6,0.8-0.6,1.2
			c-0.4,9,1.1,16.2,9.2,21.5c3.4,2,7,2.4,10.4,1.4c4.8-1.5,7.9-5.6,9.4-8l0.3-0.5l0.4,0.4c0.7,0.6,1.5,1.1,2.4,1.6
			c1.6,0.8,3.4,1.2,5.5,1.2c4.6,0,8.9-2,10.1-2.6l0.2,0.4l-0.2-0.4c0.8-0.4,1.1-1.4,0.7-2.1c-0.4-0.8-1.4-1.1-2.2-0.7
			c-0.2,0.1-4.3,2.3-8.7,2.3c-1.5,0-2.9-0.3-4.1-0.8c-2.5-1.2-4-3.5-4.7-7.1c-2.5-13.4,11.6-22.5,11.7-22.6c0.7-0.5,0.9-1.5,0.5-2.2
			c-0.5-0.7-1.5-0.9-2.2-0.5c-0.2,0.1-16,10.4-13.1,25.8c0.3,1.6,0.8,3.1,1.4,4.4l0.1,0.3l-0.2,0.2c0,0,0,0.1-0.1,0.1
			c-0.1,0.3-3.2,6.3-8.3,7.9c-2.5,0.8-5.2,0.4-7.8-1.1c-6.9-4.5-8.1-10.3-7.7-18.7c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.5-1.1-0.5
			C152.7,117,152.7,117,152.6,117z"/>
            </g>
            <g>
                <path className="st0" d="M171.5,128.1c-0.2,1.1-1.3,1.9-2.4,1.7c-1.1-0.2-1.9-1.3-1.7-2.4c2-11-0.5-16.5-3-19.3
			c-2.5-2.7-6-3.8-10.3-3.3c-4.1,0.7-7.4,2.7-9.7,6c-5.8,8.2-3.5,21.7-3.5,21.8c0.2,1.1-0.6,2.2-1.7,2.4c-0.1,0-0.2,0-0.4,0
			c-1,0-1.9-0.7-2.1-1.7c-0.1-0.6-2.6-15.3,4.2-24.9c0.8-1.1,1.6-2.1,2.6-3c-4.9-1.1-8.6-4.6-10.4-10c-2.5-7.5,0.1-13.6,2-16.8
			c0.9-1.5,1.7-2.3,1.8-2.5c0.8-0.9,2.1-0.9,3-0.2c0.9,0.8,0.9,2.1,0.2,3c0,0-0.2,0.2-0.4,0.4c-1.2,1.6-5,7.3-2.6,14.7
			c1.8,5.3,10.7,7.8,12.5,7.5s2.8-0.6,4-0.8c5.8-0.6,10.6,1,13.9,4.6C170.6,108.6,173.9,115.3,171.5,128.1z"/>
                <path className="st0" d="M138.8,135.5c-1.3,0-2.3-0.9-2.6-2.1c-0.1-0.6-2.7-15.5,4.3-25.3c0.6-0.9,1.3-1.7,2-2.4
			c-4.7-1.4-8.2-5-9.9-10.2c-2.6-7.7,0.2-14.1,2.1-17.2c0.6-0.9,1.1-1.7,1.8-2.5c1-1.1,2.6-1.2,3.7-0.3c1.1,1,1.2,2.6,0.2,3.7
			l-0.3,0.4c-1.2,1.5-4.9,7.1-2.5,14.3c1.7,5.1,10.5,7.4,11.9,7.1l0.9-0.2c1.3-0.3,2.2-0.5,3.1-0.6c5.9-0.7,10.9,1,14.4,4.8
			c3.2,3.5,6.6,10.4,4.2,23.3l0,0c-0.3,1.4-1.6,2.3-3,2.1c-0.7-0.1-1.3-0.5-1.7-1.1c-0.4-0.6-0.5-1.3-0.4-1.9
			c2-10.8-0.5-16.2-2.9-18.8c-2.4-2.6-5.7-3.6-9.9-3.2c-4,0.7-7.1,2.6-9.4,5.8c-5.7,8.1-3.4,21.3-3.4,21.4c0.1,0.7,0,1.4-0.4,1.9
			c-0.4,0.6-1,0.9-1.7,1.1C139.1,135.5,138.9,135.5,138.8,135.5z M138.4,75.8c-0.4,0-0.9,0.2-1.2,0.5c-0.6,0.7-1.2,1.5-1.7,2.4
			c-1.8,3-4.4,9-2,16.4c1.7,5.2,5.2,8.6,10.1,9.7l0.9,0.2l-0.7,0.6c-0.9,0.8-1.8,1.8-2.5,2.9c-6.7,9.5-4.2,24-4.1,24.6
			c0.2,0.8,1,1.4,1.8,1.3c0.4-0.1,0.8-0.3,1-0.7c0.2-0.3,0.3-0.8,0.3-1.2c0-0.1-2.3-13.8,3.6-22.2c2.4-3.4,5.8-5.5,10-6.2
			c4.5-0.5,8.2,0.7,10.8,3.5c2.6,2.8,5.2,8.5,3.2,19.7c-0.1,0.4,0,0.8,0.3,1.2c0.2,0.3,0.6,0.6,1,0.7c0.9,0.2,1.7-0.4,1.9-1.3
			c2.3-12.5-0.9-19.1-4-22.4c-3.3-3.5-8-5.1-13.5-4.5c-0.9,0.2-1.8,0.3-3,0.6l-0.9,0.2c-2,0.4-11.2-2.2-13-7.8
			c-2.5-7.6,1.4-13.6,2.6-15.2l0.4-0.5c0.6-0.7,0.5-1.7-0.1-2.2C139.2,75.9,138.8,75.8,138.4,75.8z"/>
            </g>
        </g>

    </svg>
);