import React, {useEffect} from 'react';
import Text from "../../assets/strings";
import "./Cookies.scss";
import {useDispatch, useSelector} from "react-redux";
import {selectLanguage, titleUpdated} from "../../feature/app/appSlice";

export const Cookies = () => {
    const lang = useSelector(state => selectLanguage(state));
    const dispatch = useDispatch();
    const title = Text[lang].web.cookies.title;

    useEffect(() => {
        dispatch(titleUpdated(title))
    }, [lang]);

    return(
        <div className="inner-content">
            <div className="medication__header">
                <div className="inner-header">
                    <div className="title">
                        <h3>{Text[lang].web.cookies.title}</h3>
                    </div>
                </div>
            </div>
            <div className={"cookie-wrap-container"} dangerouslySetInnerHTML={{__html: Text[lang].web.cookies.text}}/>
        </div>
    )
};