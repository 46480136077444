import React from "react";
import pt_PT from "../../assets/img/graph/pt_PT.png";
import en_GB from "../../assets/img/graph/en_GB.png";
import pt_BR from "../../assets/img/graph/pt_PT.png";
import es_ES from "../../assets/img/graph/es_ES.png";
import fr_FR from "../../assets/img/graph/fr_FR.png";
import de_DE from "../../assets/img/graph/de_DE.png";

export const Graph = (props) => {
  switch (props.lang) {
    case "pt_PT":
      return <img src={pt_PT} alt="" />;
    case "en_GB":
      return <img src={en_GB} alt="" />;
    case "es_ES":
      return <img src={es_ES} alt="" />;
    case "fr_FR":
      return <img src={fr_FR} alt="" />;
    case "de_DE":
      return <img src={de_DE} alt="" />;
    default:
      return <img src={pt_BR} alt="" />;
  }
};
