import {createSlice} from "@reduxjs/toolkit";
import config from "../../config/business/config";

const initialState = {
    hash: '',
    hashIsValid: true,
    lang: localStorage.getItem("lang") === null ? config.languages[0] : localStorage.getItem("lang"),
    theme: localStorage.getItem("theme") === null ? config.companies[0].title : localStorage.getItem("theme"),
    title: '',
    cookiesType: localStorage.getItem("cookies")
};


const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        languageUpdated: (state, action) => {
            state.lang = action.payload;
            localStorage.setItem("lang", action.payload);
        },
        themeUpdated: (state, action) => {
            state.theme = action.payload;
            localStorage.setItem("theme", action.payload);
        },
        hashUpdated: (state, action) => {
            state.hash = action.payload
        },
        hashValidated: (state, action) => {
            state.hashIsValid = action.payload
        },
        titleUpdated: (state, action) => {
            state.title = action.payload
        },
        cookieTypeUpdated: (state, action) => {
            state.cookiesType = action.payload;
            localStorage.setItem('cookies', action.payload);
        }
    }
});

export const selectLanguage = state => state.entities.app.lang;
export const selectHash = state => state.entities.app.hash;
export const selectHashIsValid = state => state.entities.app.hashIsValid;
export const selectCookieType = state => state.entities.app.cookiesType;
export const selectTitle = state => state.entities.app.title;
export const selectTheme = state => state.entities.app.theme;

export const {
    languageUpdated,
    themeUpdated,
    hashUpdated,
    hashValidated,
    titleUpdated,
    cookieTypeUpdated} = appSlice.actions;

export default appSlice.reducer