import React from "react";

export const LowEffect = (props) => (
    <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}>

        <path className="st0" d="M148.8,8.8C71.9,8.8,9,71.1,9.3,146.9c0.1,25,6.7,48.4,18.4,68.5c12.6,21.8,31,39.9,53.2,52.1
	c20.3,11.2,43.7,17.5,68.7,17.4c24.3-0.1,47.1-6.2,66.9-17c22.2-12.1,40.6-30,53.3-51.7c11.9-20.4,18.7-44.2,18.7-69.6
	C288.3,70.3,226,8.8,148.8,8.8z M226.5,149.4c-0.1-0.6-0.3-1.2-0.7-1.7C226.2,148.2,226.5,148.8,226.5,149.4z M100.4,216.5
	c0,3.8-3.1,6.9-6.9,6.9H78c-3.8,0-6.9-3.1-6.9-6.9v-86.3c0-3.8,3.1-6.9,6.9-6.9h15.5c3.8,0,6.9,3.1,6.9,6.9V216.5z M140.8,215.9
	c0,3.8-3.1,6.9-6.9,6.9h-15.5c-3.8,0-6.9-3.1-6.9-6.9v-63.1c0-3.8,3.1-6.9,6.9-6.9h15.5c3.8,0,6.9,3.1,6.9,6.9V215.9z M181,216.5
	c0,3.8-3.1,6.9-6.9,6.9h-15.5c-3.8,0-6.9-3.1-6.9-6.9v-41.3c0-3.8,3.1-6.9,6.9-6.9h15.5c3.8,0,6.9,3.1,6.9,6.9V216.5z M221.3,216.5
	c0,3.8-3.1,6.9-6.9,6.9h-15.5c-3.8,0-6.9-3.1-6.9-6.9v-31c0-3.8,3.1-6.9,6.9-6.9h15.5c3.8,0,6.9,3.1,6.9,6.9V216.5z M225.9,151.7
	c0-0.1,0.1-0.1,0.1-0.2c-0.4,0.6-0.9,1.1-1.5,1.3c0.3-0.1,0.6-0.2,0.9-0.5C225.5,152.1,225.7,151.9,225.9,151.7
	c-0.2,0.4-0.5,0.7-0.7,1c-2.6,3.1-5.2,6-7.9,9c-1.7,1.9-3.8,2.7-5.9,1c-2.3-1.9-1.8-4.1,0-6.2c0.2-0.2,1.1-1.3,2.4-2.7
	c-0.4,0-0.7,0-1.1,0c-22.1,0-54.2-6.6-74.7-18.6c-24.4-14.2-48.7-31.4-60.4-60.2c-0.7-1.7,0.1-3.7,1.8-4.4c1.7-0.7,3.7,0.1,4.4,1.8
	c11,26.9,34.2,43.3,57.6,56.9c19.7,11.5,52.1,18.1,73.2,17.7c-1.9-1.8-3.3-3.3-3.5-3.5c-1.8-2-2.4-4.2-0.3-6.2
	c2-1.8,4.2-1.1,5.9,0.7c2.8,2.9,5.6,5.7,8.2,8.7c0.3,0.3,0.5,0.6,0.7,0.9c-0.2-0.2-0.3-0.4-0.6-0.6c-0.3-0.3-0.7-0.5-1.2-0.5
	c1.2,0.4,2.2,1.4,2.4,2.8c0.1,0.4,0,0.7,0,1.1c0.1-0.2,0.1-0.5,0.1-0.7C226.5,150.3,226.4,151,225.9,151.7z"/>
    </svg>
);