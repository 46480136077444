import React from "react";
import './goBack.scss';
import {FaArrowLeft} from "react-icons/fa";

const GoBack = (props) => {
    return (
        <button
        className="go-back pull-right"
        onClick={ ()=> props.history.goBack()}>
        <FaArrowLeft size={20}/>
        </button>
    )
};

export default GoBack;