import React from "react";

export const LevelNull = (props) => (
    <svg version="1.1" id="Layer_1"
         height={`${props.size - 1}px`}
         width={`${props.size - 1}px`}
         focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         style={{position: 'relative', bottom:'2px'}}>

        <g>
            <path className="st0" d="M40.8,235.4L237.7,38.4C182.4-4.5,102.8-1.2,52,49.6C1.2,100.4-2.1,180,40.8,235.4z"/>
            <path className="st0" d="M256.8,57.5L59.9,254.5c55.3,42.9,134.9,39.6,185.7-11.2S299.8,112.9,256.8,57.5z"/>
        </g>
    </svg>
);