import React from 'react';
import Table from 'react-bootstrap/Table';
import {Link} from 'react-router-dom';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Text from "../../../assets/strings";
import {EvidenceLevel} from "../EvidenceLevel";
import {LevelNull} from "../icons/levels/LevelNull";
import {GuideLines} from "../icons/GuideLines";
import {DrugLabels} from "../icons/DrugLabels";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../../feature/app/appSlice";

export const DynamicsTable = (props) => {
    const lang = useSelector(state => selectLanguage(state));

    return (
        <div className="inner-data-table">
            <h5>{props.title}</h5>

            <Table responsive striped hover bordered>
                <thead>
                <tr>
                    <th>{Text[lang].web.medication.gene}</th>
                    <th>{Text[lang].web.medication.phenotype}</th>
                    <th>{Text[lang].web.medication['evidence-levels']}</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map((gene, i) => {
                        if (gene.adverse) {
                            return (
                                <tr key={i}>
                                    <td>
                                        <Link
                                            to={{
                                                pathname: `/${props.hash}/medication/${props.drug}/gene/${gene.gene}/?ctx=phdmcs&gnctx=advrs&rs=${gene.rs}`,
                                            }}>
                                            <i>{gene.gene}</i>
                                        </Link>
                                    </td>
                                    <td>{gene.adverse.text}</td>
                                    <td align="center">
                                        {
                                            gene.adverse.evidenceLevel !== null ?
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{show: 250, hide: 400}}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            {Text[lang].web["evidence-level"][gene.adverse.evidenceLevel]}
                                                        </Tooltip>
                                                    }
                                                >
                                                <span style={{cursor: 'pointer'}} >
                                                    <EvidenceLevel level={gene.adverse.evidenceLevel} size={30}/>
                                                </span>
                                                </OverlayTrigger>
                                                :
                                                null
                                        }

                                        {
                                            gene.adverse.guidelines === 1 ?
                                                <OverlayTrigger
                                                    placement='left'
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {Text[lang].web.guidelines}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span><GuideLines size={30}/></span>
                                                </OverlayTrigger>
                                                : null
                                        }

                                        {
                                            gene.adverse.drugLabel === 1 ?
                                                <OverlayTrigger
                                                    placement='left'
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {Text[lang].web['drug-label']}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span><DrugLabels size={30}/></span>
                                                </OverlayTrigger>
                                                : null
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    })
                }
                {
                    props.data.map((gene, i) => {
                        if (gene.efficacy) {
                            return (
                                <tr key={i}>
                                    <td>
                                        <Link
                                        to={{
                                            pathname: `/${props.hash}/medication/${props.drug}/gene/${gene.gene}/?ctx=phdmcs&gnctx=efcy&rs=${gene.rs}`,
                                        }}>
                                            <i>{gene.gene}</i>
                                        </Link>
                                    </td>
                                    <td>{gene.efficacy.text}</td>
                                    <td align="center">
                                        {
                                            gene.efficacy.evidenceLevel !== null ?
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{show: 250, hide: 400}}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            {Text[lang].web["evidence-level"][gene.efficacy.evidenceLevel !== null ? gene.efficacy.evidenceLevel : 'no-evidence' ]}
                                                        </Tooltip>
                                                    }
                                                >
                                                <span style={{cursor: 'pointer'}}>
                                                    <EvidenceLevel level={gene.efficacy.evidenceLevel}
                                                                   size={30}/>
                                                </span>
                                                </OverlayTrigger>
                                            :
                                                <LevelNull size={30}/>
                                        }

                                        {
                                            gene.efficacy.guidelines === 1 ?
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {Text[lang].web.guidelines}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span><GuideLines size={30}/></span>
                                                </OverlayTrigger>
                                                : null
                                        }

                                        {
                                            gene.efficacy.drugLabel === 1 ?
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {Text[lang].web['drug-label']}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span><DrugLabels size={30}/></span>
                                                </OverlayTrigger>
                                                : null
                                        }

                                    </td>
                                </tr>
                            )
                        }
                    })
                }
                </tbody>
            </Table>
        </div>
    );
};