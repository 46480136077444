import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { App } from "./App";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import NoMatch from "./components/NoMatch/NoMatch";
import { Provider } from "react-redux";
import store from "./context/store";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

let instance = null;

if (process.env.NODE_ENV === "production") {
  instance = createInstance({
    urlBase: process.env.REACT_APP_FRONTEND_HOST,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    trackerUrl: `${process.env.MATOMO_HOST}/matomo.php`,
    srcUrl: `${process.env.MATOMO_HOST}/matomo.js`,
    linkTracking: true,
  });
}

ReactDOM.render(
  <MatomoProvider value={instance}>
    <Router onUpdate={() => window.scrollTo(0, 0)} basename='webapp'>
      <ScrollToTop>
        <Switch>
          <Route exact path="/:hash/ups" component={NoMatch} />
          <Provider store={store}>
            <Route path="/:hash" component={App} />
          </Provider>
        </Switch>
      </ScrollToTop>
    </Router>
  </MatomoProvider>,
  document.getElementById("root")
);
