import React, { useEffect, useState } from "react";
import { useCookieConsents } from "@enzsft/react-cookie-consents";
import Strings from "../../assets/strings";
import { Link } from "react-router-dom";
import "./CookieBanner.scss";
import { Accordion, Card, Button, Form } from "react-bootstrap";
import axios from "../../axios/axiosBack";
import { useDispatch, useSelector } from "react-redux";
import {
  cookieTypeUpdated,
  selectHash,
  selectLanguage,
} from "../../feature/app/appSlice";

const CookieBanner = () => {
  const dispatch = useDispatch();
  const hash = useSelector((state) => selectHash(state));
  const lang = useSelector((state) => selectLanguage(state));
  const Text = Strings[lang].web;
  const linkText = Strings[lang].web.cookies["know-more"];
  const cookieConsents = useCookieConsents();

  const [isAllowed, setIsAllowed] = useState(true); // analytics
  const [savedConsent, setsavedConsent] = useState(null); // consent from Database
  const [dataCollection, setdataCollection] = useState(true); // data collection
  const [consent, setConsent] = useState(false); // final consent

  useEffect(() => {
    async function fetchData() {
      if (cookieConsents.get().length === 0 && hash !== undefined) {
        const response = await axios.get(`/${hash}/consent`);
        if (typeof response?.data[0]?.allowDataCollect === "undefined") {
          return setsavedConsent(null);
        } else {
          return setsavedConsent(response?.data[0]?.allowDataCollect);
        }
      }
    }
    fetchData();
  }, [hash, cookieConsents]);

  if (cookieConsents.get().length > 0) {
    return null;
  }

  const handleChange = () => {
    setIsAllowed(!isAllowed);
  };

  const handleConsent = () => {
    if (isAllowed) {
      cookieConsents.add("analytics");
      dispatch(cookieTypeUpdated("all"));
    } else {
      cookieConsents.add("essential");
      dispatch(cookieTypeUpdated("essential"));
    }
    if (savedConsent === null) {
      axios.put(`/${hash}/consent`, { consent: dataCollection }).then((r) => {
        console.log(r);
      });
    }
  };

  return (
    <div className="overlay">
      <div className="banner">
        <div className={"message-container"}>
          <h5>{Text.consent.title}</h5>
          <p>{Text.consent.p1}</p>
          <Link to={`/${hash}/cookies`} className="cookie-know-more">
            {linkText}
          </Link>
        </div>
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                {Text.consent.manage}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <Form.Check
                    disabled
                    type="switch"
                    label={Text.consent.necessary}
                    id="disabled-custom-switch"
                    checked={true}
                  />
                  <Form.Check
                    type="switch"
                    id="google-analytics"
                    label={Text.consent.analytics}
                    checked={isAllowed}
                    onChange={handleChange}
                  />
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <br />

        {savedConsent === null && (
          <>
            <label>
              <input
                type="checkbox"
                className="filled-in"
                checked={dataCollection}
                onChange={(e) => setdataCollection(e.target.checked)}
                name="dataCollection"
              />
              <span>{Text.consent.dataCollection}</span>
            </label>

            <br />
          </>
        )}

        <label>
          <input
            type="checkbox"
            className="filled-in"
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            name="consent"
          />
          <span>{Text.consent.consent}</span>
        </label>

        <br />

        <div dangerouslySetInnerHTML={{ __html: Text.consent.link }} />

        <div className={"banner-button-container"}>
          <button
            className={"accept"}
            onClick={handleConsent}
            disabled={!consent}
          >
            {Text.consent.close}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
