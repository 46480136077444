import React from "react";

export const MetaAltered = (props) => (
    <svg version="1.1" id="Layer_1"
         height={`${props.size}px`}
         width={`${props.size}px`}
         focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 297.6 293.7" xmlSpace="preserve">

        <g>
            <path className="st0" d="M111.1,114.7c-6.2,1.6-9.9,7.9-8.3,14.1c1.6,6.2,7.9,9.9,14.1,8.3c0.2,0,0.3-0.1,0.5-0.1
		c5.9-1.8,9.4-7.9,7.8-13.9C123.5,116.9,117.2,113.2,111.1,114.7z"/>
            <path className="st0" d="M174.4,141.1c-0.4,0-0.8,0-1.2,0c-1.7,0-3.3,0.2-4.9,0.7c-6.8,2-11.6,8.2-12.1,15.2c0,0.4,0,0.8,0,1.2
		c0,4.1,1.5,8.1,4.2,11.2c3,3.4,7.2,5.5,11.7,5.8c2.1,0.1,4.1-0.1,6.1-0.7c6.8-2,11.6-8.2,12.1-15.2c0.3-4.6-1.2-9-4.2-12.4
		C183.1,143.5,178.9,141.4,174.4,141.1z"/>
            <path className="st0" d="M148.8,9.2C71.9,9.2,9,71.5,9.2,147.3c0.1,25,6.7,48.4,18.4,68.5c12.6,21.8,31,39.9,53.2,52.1
		c20.3,11.2,43.7,17.5,68.7,17.4c24.3-0.1,47.1-6.2,66.9-17c22.2-12.1,40.6-30,53.3-51.7c11.9-20.4,18.7-44.2,18.7-69.6
		C288.2,70.7,225.9,9.3,148.8,9.2z M56.1,123.3c12.2-51.1,63.7-82.8,114.8-70.6c24.4,5.8,45.2,20.7,58.6,42l4.8-2.5
		c0.9-0.5,2-0.4,2.9,0.1c0.9,0.5,1.4,1.5,1.4,2.5l0.2,19.9c0,1.1-0.6,2.2-1.6,2.7c-1,0.5-2.2,0.4-3.1-0.3L218,105.3
		c-0.8-0.6-1.3-1.6-1.2-2.6c0.1-1,0.7-1.9,1.6-2.4l4.7-2.4c-12.4-19.4-31.5-33-53.9-38.3c-47.3-11.3-95,18.1-106.3,65.4
		c-0.4,1.6-1.8,2.7-3.4,2.7c-0.3,0-0.5,0-0.8-0.1C56.8,127.1,55.7,125.2,56.1,123.3z M202.8,181.5c1.2,1.4,1,3.4-0.3,4.6l-5.5,4.8
		c-0.6,0.6-1.5,0.8-2.4,0.8c-0.9-0.1-1.7-0.5-2.2-1.1l-4.6-5.3c-3,1.6-6.2,2.7-9.6,3.2l-0.5,7c-0.1,0.9-0.5,1.7-1.1,2.2
		c-0.6,0.5-1.4,0.8-2.2,0.8c-0.1,0-0.2,0-0.2,0l-7.3-0.5c-1.8-0.1-3.2-1.7-3-3.5l0.5-7c-3.3-1-6.3-2.5-9.1-4.5l-5.3,4.6
		c-0.7,0.6-1.5,0.8-2.4,0.8c-0.9-0.1-1.7-0.5-2.2-1.1l-4.8-5.5c-0.6-0.7-0.9-1.5-0.8-2.4c0.1-0.9,0.5-1.7,1.1-2.2l5.3-4.6
		c-1.6-3-2.7-6.2-3.2-9.6l-7-0.5c-0.9-0.1-1.7-0.5-2.2-1.1c-0.6-0.7-0.9-1.5-0.8-2.4l0.5-7.3c0.1-1.8,1.7-3.2,3.5-3l7,0.5
		c1-3.3,2.5-6.3,4.5-9.1l-4.6-5.3c-1.2-1.4-1-3.4,0.3-4.6l5.5-4.8c0.6-0.6,1.5-0.8,2.4-0.8c0.9,0.1,1.7,0.5,2.2,1.1l4.6,5.3
		c3-1.6,6.2-2.7,9.6-3.2l0.5-7c0.1-0.9,0.5-1.7,1.1-2.2c0.4-0.3,0.8-0.5,1.2-0.7c0.4-0.1,0.8-0.2,1.2-0.1l7.3,0.5
		c1.8,0.1,3.2,1.7,3,3.5l-0.5,7c3.3,1,6.3,2.5,9.1,4.5l5.3-4.6c0.7-0.6,1.5-0.8,2.4-0.8c0.9,0.1,1.7,0.5,2.2,1.1l4.8,5.5
		c0.6,0.7,0.9,1.5,0.8,2.4c-0.1,0.9-0.5,1.7-1.1,2.2l-5.3,4.6c1.6,3,2.7,6.2,3.2,9.6l7,0.5c1.8,0.1,3.2,1.7,3,3.5l-0.5,7.3
		c-0.1,0.9-0.5,1.7-1.1,2.2c-0.7,0.6-1.5,0.9-2.4,0.8l-7-0.5c-1,3.3-2.5,6.3-4.5,9.1L202.8,181.5z M126.4,150.6
		c0.4,1.7-0.6,3.5-2.4,4l-5.2,1.3c-0.3,0.1-0.5,0.1-0.8,0.1c-1.5,0-2.8-1-3.2-2.5l-1.1-4.3c-2.1,0-4.2-0.3-6.2-0.9l-2.3,3.8
		c-0.4,0.8-1.1,1.3-2,1.5c-0.8,0.2-1.7,0.1-2.5-0.3l-4.6-2.7c-1.6-0.9-2.1-2.9-1.1-4.5l2.3-3.8c-1.5-1.5-2.7-3.2-3.8-5l-4.3,1.1
		c-0.8,0.2-1.7,0.1-2.5-0.4c-0.7-0.4-1.3-1.2-1.5-2l-1.3-5.2c-0.4-1.7,0.6-3.5,2.4-4l4.3-1.1c0-2.1,0.3-4.2,0.9-6.2l-3.8-2.3
		c-0.7-0.4-1.3-1.1-1.5-2c-0.2-0.8-0.1-1.7,0.4-2.5l2.7-4.6c0.4-0.8,1.2-1.3,2-1.5c0.8-0.2,1.7-0.1,2.5,0.4l3.8,2.3
		c1.5-1.5,3.2-2.7,5-3.8l-1.1-4.3c-0.2-0.8-0.1-1.7,0.4-2.5c0.4-0.7,1.1-1.2,1.9-1.5l0.1,0L109,96c1.7-0.4,3.5,0.6,4,2.3l1.1,4.3
		c2.1,0,4.2,0.3,6.2,0.9l2.3-3.8c0.4-0.7,1.2-1.3,2-1.5c0.8-0.2,1.7-0.1,2.5,0.4l4.6,2.7c0.8,0.4,1.3,1.2,1.5,2
		c0.2,0.8,0.1,1.7-0.4,2.5l-2.3,3.8c1.5,1.5,2.7,3.2,3.8,5l4.3-1.1c0.8-0.2,1.7-0.1,2.5,0.4c0.7,0.4,1.3,1.1,1.5,2l1.3,5.2
		c0.5,1.7-0.6,3.5-2.3,4l-4.3,1.1c0,2.1-0.3,4.2-0.9,6.2l3.8,2.3c0.8,0.4,1.3,1.1,1.5,2c0.2,0.8,0.1,1.7-0.4,2.5l-2.7,4.6
		c-0.4,0.7-1.2,1.3-2,1.5c-0.8,0.2-1.7,0.1-2.5-0.4l-3.8-2.3c-1.5,1.5-3.2,2.7-5,3.8L126.4,150.6z M241.4,171.3
		c-10.4,43.7-49.6,73.2-92.7,73.2c-7.3,0-14.7-0.9-22.1-2.6c-24.4-5.8-45.2-20.7-58.6-42l-4.8,2.5c-0.9,0.5-2,0.4-2.9-0.1
		c-0.9-0.5-1.4-1.5-1.4-2.5l-0.2-19.9c0-1.1,0.6-2.2,1.6-2.7c1-0.5,2.2-0.4,3.1,0.3l16.1,11.8c0.8,0.6,1.3,1.6,1.2,2.6
		c-0.1,1-0.7,1.9-1.6,2.4l-4.7,2.4c12.4,19.4,31.5,33,53.9,38.3c47.3,11.3,95-18,106.3-65.4c0.4-1.9,2.3-3.1,4.2-2.6
		C240.7,167.5,241.9,169.4,241.4,171.3z"/>
        </g>
    </svg>
);