import React from "react";

export const SideEffects = (props) => (
    <svg version="1.1" id="Layer_1"
         height={`${props.size}px`}
         width={`${props.size}px`}
         focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve">

            <path className="st0" d="M10,1.3c-4.9,0-8.8,3.9-8.8,8.7c0,1.6,0.4,3.1,1.2,4.3c0.8,1.4,2,2.5,3.4,3.3c1.3,0.7,2.8,1.1,4.3,1.1
    c1.5,0,3-0.4,4.2-1.1c1.4-0.8,2.6-1.9,3.4-3.3c0.8-1.3,1.2-2.8,1.2-4.4C18.8,5.2,14.9,1.3,10,1.3z M5.8,8.2c0-0.8,0.7-1.5,1.5-1.5
    s1.5,0.7,1.5,1.5S8.1,9.6,7.3,9.6S5.8,9,5.8,8.2z M12.6,13.6H7.4c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h5.2c0.2,0,0.3,0.1,0.3,0.3
    S12.7,13.6,12.6,13.6z M13.3,9.3c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S13.8,9.3,13.3,9.3z"/>
    </svg>
);