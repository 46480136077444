import React from "react";
import pgx from '../../assets/pgx.png'
import mymed from '../../assets/mymed.png'
import bluehc from '../../assets/blueHC.svg'
import unilabs from '../../assets/unilabs.png'
import pathotrier from '../../assets/pathotrier.png'
import invenimus from '../../assets/invenimus.png'
import Navigation from "./Navigation/Navigation";
import {useStore} from "../../context";
import Empty from "../../assets/img/empty.png";
import Filled from "../../assets/img/filled.png";
import {useWindowSize} from "../../hooks/windowSize";
import {useWindowWidth} from "../../hooks/windowWidth";
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import {selectHash, selectHashIsValid, selectLanguage, selectTheme, selectTitle} from "../../feature/app/appSlice";
import { log } from "winston";

export const Header = () => {

    const hashIsValid = useSelector(state=>selectHashIsValid(state));
    const hash = useSelector(state => selectHash(state));
    const title = useSelector(state => selectTitle(state));
    const theme = useSelector(state => selectTheme(state));
    let logo;

    switch (theme) {
        case 'hg':
            logo = pgx
            break;

        case 'iProprium':
            logo = mymed
            break;

        case 'blueHC':
            logo = bluehc
            break;
        case 'unilabs':
            logo = unilabs
            break;

        case 'pathotrier':
            logo = pathotrier
            break;
        
        case 'invenimus':
            logo = invenimus
            break;
    
        default:
            logo = pgx
            break;
    }

    const height = useWindowSize();
    const {width} = useWindowWidth();

    // console.log('size',height);
    // console.log('width',width);

    const showLogo = width <= 767 && height >= 60 ? 'hid-logo' : 'hid-logo hide-logo';

    const renderLogoContainer = width <= 767 ? 'header-logo' : 'hide';

    const showName = width <= 767 && height >= 60 ? 'hid-name show-name cut-long-title' : 'hid-name';

    const showCenterName = width >= 768 && height >= 60 ? 'hid-name show-name' : 'hid-name';

    return (
        <header data-testid="header" className={theme}>
            <div className="background">
                <div className="filled" style={{backgroundImage: `url(${Filled})`}}/>
                <div className="empty" style={{backgroundImage: `url(${Empty})`}}/>
            </div>
            <nav>
                <div className="logo">
                    <div className="header-logo">
                        <div className={showLogo}>
                            <Link to={`/${hash}/`}><img src={logo} alt="logo"/></Link>
                        </div>
                    </div>
                    <div className={renderLogoContainer}>
                        <div className={showName}>
                            <h5 style={{color: "#fff"}}>{title}</h5>
                        </div>
                    </div>
                </div>
                <div className="name">
                    <div className="header-name">
                        <div className={showCenterName}>
                            <h4 style={{color: "#fff"}}>{title}</h4>
                        </div>
                    </div>
                </div>
                <div className={hashIsValid ? 'settings' : 'settings disabled-link'}>
                    <ul>
                        <li className="settings__menu"><Navigation/></li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};