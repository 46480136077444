import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSelector} from 'reselect';
import axios from "../../axios/axiosBack";


export const fetchMeds = createAsyncThunk(
    'meds/fetchMeds', async ({hash, categoryId, str}) => {
        const response = await axios.get(`/${hash}/meds/${categoryId}${str}`);
        return response.data;
    });

const initialState = {
    categories: {
        1:{},
        2:{},
        3:{},
        4:{},
        5:{}
    },
    error: null,
    status: 'idle'
};

const medicationSlice = createSlice({
    name: 'meds',
    initialState,
    reducers: {
        stateRemoved: () => initialState
    },
    extraReducers: {
        [fetchMeds.pending]: state => {
            state.status = 'loading';
        },
        [fetchMeds.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            const {categoryId} = action.meta.arg;
            state.categories[categoryId] = {...action.payload};
        },
        [fetchMeds.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message
        }
    }
});

export const selectAllCategories = state => state.entities.meds.categories;


export const selectMedsForCategory = categoryId => createSelector(
    selectAllCategories,
    medications => medications[categoryId]
);



export const {stateRemoved} = medicationSlice.actions;


export default medicationSlice.reducer

