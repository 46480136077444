import React from "react";

export const GuideLines = (props) => (
    <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}
        style={{paddingBottom: '1px'}}>
        <g>
            <path className="st0" d="M7.3,4.5C6.5,4.3,4,5.1,3.4,5.3c0,0-0.1,0-0.1,0v0.1v7.2c0.5-0.2,1.9-0.7,2.7-0.8c0.9-0.1,1.8-0.2,2.7,0.8v-7
		c0-0.1-0.1-0.3-0.3-0.5C8.1,4.9,7.8,4.6,7.3,4.5z M5,6.7c0.1-0.1,0.2-0.1,0.2,0l0.4,0.4l0.9-0.9c0.1-0.1,0.2-0.1,0.2,0
		c0.1,0.1,0.1,0.2,0,0.2l-1,1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0L5,6.9C5,6.8,5,6.8,5,6.7z M7.6,10.4c-1-0.2-3.2,0.4-3.2,0.4c0,0,0,0,0,0
		c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.2c0.1,0,2.3-0.6,3.4-0.4c0.1,0,0.1,0.1,0.1,0.2C7.7,10.3,7.6,10.4,7.6,10.4z M7.6,9.6
		C6.6,9.4,4.4,10,4.3,10c0,0,0,0,0,0c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.2c0.1,0,2.3-0.6,3.4-0.4c0.1,0,0.1,0.1,0.1,0.2
		C7.7,9.6,7.6,9.6,7.6,9.6z M7.6,8.9c-1-0.2-3.2,0.4-3.2,0.4c0,0,0,0,0,0c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.2
		c0.1,0,2.3-0.6,3.4-0.4c0.1,0,0.1,0.1,0.1,0.2C7.7,8.8,7.6,8.9,7.6,8.9z"/>
            <path className="st0" d="M9.7,12.5c0.5-0.2,1.1-0.3,1.7-0.2c0.6,0.1,1.9,0.5,2.4,0.7v-0.3c-0.6-0.2-1.5-0.5-2-0.5
		C11,12.1,10.3,12.1,9.7,12.5z"/>
            <path className="st0"
                  d="M6,12.1c-0.5,0-1.4,0.3-2.1,0.5v0.3c0.5-0.2,1.7-0.6,2.4-0.7c0.8-0.1,1.4,0,2,0.3C7.6,12,6.8,12.1,6,12.1z"/>
            <path className="st0" d="M10.4,4.5C9.5,4.6,9,5.5,9,5.6v7.1c0.9-0.9,1.9-0.9,2.7-0.8c0.7,0.1,2.2,0.6,2.7,0.8V5.4
		C13.9,5.2,11.3,4.3,10.4,4.5z M13.3,10.8C13.2,10.8,13.2,10.8,13.3,10.8c-0.1,0-2.3-0.6-3.3-0.4c-0.1,0-0.1,0-0.2-0.1
		c0-0.1,0-0.1,0.1-0.2c1.1-0.2,3.3,0.4,3.4,0.4c0.1,0,0.1,0.1,0.1,0.2C13.4,10.7,13.3,10.8,13.3,10.8z M13.3,10
		C13.3,10,13.2,10,13.3,10C13.2,10,11,9.4,10,9.6c-0.1,0-0.1,0-0.2-0.1s0-0.1,0.1-0.2c1.1-0.2,3.3,0.4,3.4,0.4
		c0.1,0,0.1,0.1,0.1,0.2C13.4,10,13.3,10,13.3,10z M13.4,9.2c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-2.2-0.6-3.2-0.4
		c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.1,0.1-0.2C11,8.4,13.2,9,13.3,9C13.4,9,13.4,9.1,13.4,9.2z M10.9,6.3c0,0,0-0.1,0-0.2s0.1,0,0.2,0
		l0.5,0.5L12,6.2c0,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2l-0.5,0.5l0.5,0.5c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.2,0L11.6,7
		l-0.5,0.5c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0,0-0.1,0-0.2l0.5-0.5L10.9,6.3z"/>
            <path className="st0" d="M8.8,0C4,0,0,4,0.1,8.8c0,1.6,0.4,3,1.2,4.3c0.8,1.4,2,2.5,3.4,3.3c1.3,0.7,2.8,1.1,4.3,1.1
		c1.5,0,3-0.4,4.2-1.1c1.4-0.8,2.6-1.9,3.4-3.3c0.8-1.3,1.2-2.8,1.2-4.4C17.6,3.9,13.7,0,8.8,0z M14.7,13l-0.2-0.1
		c0,0-0.2-0.1-0.5-0.2v0.6l-0.2-0.1c0,0-1.7-0.6-2.5-0.7c-0.9-0.1-1.8,0.1-2.4,0.7c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0
		c-0.6-0.5-1.4-0.8-2.4-0.7c-0.8,0.1-2.5,0.7-2.5,0.7l-0.2,0.1v-0.6c-0.3,0.1-0.5,0.2-0.5,0.2L3,13V5.2l0.1,0c0,0,0.2-0.1,0.5-0.2
		c0.9-0.3,3-0.9,3.8-0.8C8.2,4.4,8.6,5,8.9,5.3C9.1,5,9.5,4.4,10.3,4.2c1.1-0.2,4.1,0.9,4.2,0.9l0.1,0V13z"/>
        </g>
    </svg>
);