import React from "react";
import {Link} from "react-router-dom";
import {FaHome} from "react-icons/fa";
import {useSelector} from "react-redux";
import {selectHash} from "../../../feature/app/appSlice";


export const NavigationFooter = () => {
    const hash = useSelector(state => selectHash(state));

    return (
        <div className="navigation-footer">
            <div className="floating-button">
                <Link to={`/${hash}/`}>
                    <div className="home">
                        <FaHome size={25}/>
                    </div>
                </Link>
            </div>
        </div>
    )
};