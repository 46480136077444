import React from "react";

export const Favorite = (props) => (
    <svg
        version="1.1"
        id="Layer_1"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        height={`${props.size}px`}
        width={`${props.size}px`}
        style={props.sidebar ? {padding: '2px 8px 8px'} : null}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        viewBox="0 0 20 20"
        xmlSpace="preserve">

        <path className="st0" d="M17.2,7.4h-4.7L11,3C10.5,1.2,9.5,1.2,9,3L7.5,7.4H2.8c-1.8,0-2.1,0.9-0.6,2L6,12.1l-1.4,4.4
            c-0.6,1.8,0.2,2.3,1.7,1.2L10,15l3.8,2.7c1.5,1.1,2.2,0.5,1.7-1.2L14,12.1l3.8-2.7C19.3,8.3,19,7.4,17.2,7.4L17.2,7.4z"/>

    </svg>
);