import React from "react";

export const Level2a = (props) => (
    <svg version="1.1" id="Layer_1"
         height={`${props.size}px`}
         width={`${props.size}px`}
         focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve"
         style={{position: 'relative', bottom:'2px'}}>

        <g>
            <polygon className="st0" points="11.7,8.8 11.2,10.6 12.3,10.6 11.7,8.8 	"/>
            <path className="st0" d="M10,1.4c-4.8,0-8.7,3.9-8.7,8.6c0,1.6,0.4,3,1.1,4.3c0.8,1.4,1.9,2.5,3.3,3.3c1.3,0.7,2.7,1.1,4.3,1.1
		c1.5,0,2.9-0.4,4.2-1.1c1.4-0.8,2.5-1.9,3.3-3.2c0.7-1.3,1.2-2.8,1.2-4.3C18.7,5.2,14.8,1.4,10,1.4z M9.2,12.3H6.2
		c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.2,0.4-0.4,0.7-0.6C7.7,10,7.8,9.9,7.9,9.8
		C8,9.7,8.1,9.6,8.2,9.5c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.2C8.1,8.5,8.1,8.5,8,8.5
		c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0.1-0.5,0.2C7.1,8.7,7.1,8.9,7.1,9.2H6.2c0-0.5,0.1-0.9,0.4-1.1c0.3-0.3,0.6-0.4,1.1-0.4
		c0.2,0,0.4,0,0.6,0.1C8.5,7.8,8.7,7.9,8.8,8C8.9,8.1,9,8.3,9.1,8.4C9.2,8.6,9.2,8.8,9.2,9c0,0.2,0,0.3-0.1,0.5
		c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.3-0.2,0.4h1.9
		V12.3z M12.9,12.3l-0.3-0.9h-1.7l-0.3,0.9h-1l1.6-4.5h1.1l1.6,4.5H12.9z"/>
        </g>
    </svg>
);