import React from "react";

export const Level1b = (props) => (
    <svg version="1.1" id="Layer_1"
         height={`${props.size}px`}
         width={`${props.size}px`}
         focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve"
         style={{position: 'relative', bottom:'2px'}}>

        <g>
            <path className="st0" d="M10,1.4c-4.8,0-8.7,3.9-8.7,8.6c0,1.6,0.4,3,1.1,4.3c0.8,1.4,1.9,2.5,3.3,3.3c1.3,0.7,2.7,1.1,4.3,1.1
		c1.5,0,2.9-0.4,4.2-1.1c1.4-0.8,2.5-1.9,3.3-3.2c0.7-1.3,1.2-2.8,1.2-4.3C18.7,5.2,14.8,1.4,10,1.4z M8.7,12.2H7.9V9.1H6.9V8.5
		c0.2,0,0.4,0,0.5,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.1,0.2-0.2C8,8,8,7.9,8,7.7h0.7V12.2z M12.7,11.9c-0.3,0.2-0.7,0.4-1.3,0.4
		h-2V7.7h2.2c0.4,0,0.8,0.1,1,0.3C12.8,8.3,13,8.6,13,9c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.2,0.3-0.4,0.3c0.1,0,0.2,0.1,0.3,0.1
		c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4C13.1,11.3,13,11.6,12.7,11.9z"/>
            <path className="st0" d="M12,10.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.2v1.2h1.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5
		C12.2,10.6,12.1,10.5,12,10.4z"/>
            <path className="st0"
                  d="M12.1,9c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v1h1.2C11.9,9.5,12.1,9.3,12.1,9z"/>
        </g>
    </svg>
);