import React from "react";
import './Diabetes';

export const Diabetes = (props) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}>
        <g>
            <g>
                <path className="st0" d="M205.3,236.1L205.3,236.1c-12-0.7-21-7-26-18.1c-2.8-6.1-3.1-12.8-1.1-19.8c1.6-5.5,3.9-11.3,7.1-17.7
			c2.7-5.4,5.5-10.8,8.2-16.1c1.6-3.1,3.2-6.3,4.8-9.4c0.8-1.5,1.5-3,2.2-4.5c0.6-1.2,1.1-2.4,1.7-3.5c0.7-1.4,1.8-2.5,3.2-2.8
			c1.2-0.4,2.5-0.2,3.6,0.5c0.8,0.5,1.6,1.3,2,2.2l2.1,4.2c5.7,11.4,11.6,23.2,17.4,34.9c2.2,4.6,4,9.4,5.1,14.2
			c1.7,7.4,0.7,14.5-3.2,21.2c-3.7,6.5-9.3,11-16.6,13.4C212.8,235.6,209.8,236,205.3,236.1L205.3,236.1z M206.6,146.1
			c-0.2,0-0.4,0-0.6,0.1c-0.7,0.2-1.3,0.8-1.8,1.7c-0.6,1.2-1.1,2.3-1.7,3.5c-0.7,1.5-1.5,3-2.2,4.5c-1.6,3.2-3.2,6.3-4.8,9.5
			c-2.7,5.3-5.5,10.7-8.1,16.1c-3.1,6.3-5.4,11.9-7,17.3c-1.9,6.5-1.5,12.6,1,18.2c4.7,10.3,13,16.1,24,16.8c4.2-0.1,7-0.4,9.7-1.3
			c6.7-2.2,11.8-6.4,15.3-12.4c3.6-6.2,4.6-12.7,3-19.5c-1.1-4.7-2.8-9.3-4.9-13.7c-5.7-11.7-11.6-23.5-17.3-34.9l-2.1-4.2
			c-0.2-0.5-0.7-0.9-1.1-1.2C207.4,146.2,207,146.1,206.6,146.1z"/>
                <path className="st0" d="M205.3,236.6L205.3,236.6c-12.2-0.7-21.4-7.1-26.5-18.4c-2.8-6.2-3.2-13-1.1-20.2c1.6-5.6,4-11.4,7.1-17.8
			c2.6-5.3,5.4-10.7,8-15.9l0.1-0.3c1.6-3.1,3.2-6.3,4.8-9.4c0.7-1.5,1.5-3,2.2-4.5c0.6-1.2,1.1-2.4,1.7-3.5c0.8-1.6,2-2.7,3.5-3.1
			c1.3-0.4,2.7-0.2,4,0.5c0.9,0.5,1.7,1.4,2.2,2.4l2.2,4.4c5.7,11.4,11.6,23.2,17.3,34.8c2.3,4.6,4,9.5,5.1,14.3
			c1.8,7.5,0.7,14.8-3.3,21.6c-3.8,6.6-9.5,11.2-16.8,13.6C212.9,236.1,209.8,236.5,205.3,236.6L205.3,236.6z M206.6,144.3
			c-0.4,0-0.7,0.1-1.1,0.2c-1.2,0.4-2.2,1.3-2.9,2.6c-0.6,1.2-1.1,2.3-1.7,3.5c-0.7,1.6-1.5,3.1-2.2,4.5c-1.6,3.2-3.2,6.3-4.8,9.5
			l-0.1,0.3c-2.6,5.2-5.4,10.5-8,15.9c-3.2,6.4-5.5,12.1-7.1,17.6c-2,6.9-1.6,13.5,1.1,19.5c5,11,13.8,17.2,25.5,17.8l0,0.5l0-0.5
			c4.4-0.1,7.3-0.5,10.3-1.4c7.1-2.4,12.6-6.8,16.3-13.2c3.8-6.6,4.9-13.6,3.2-20.8c-1.1-4.8-2.8-9.6-5.1-14.1
			c-5.7-11.6-11.6-23.4-17.3-34.8l-2.2-4.4c-0.4-0.8-1.1-1.5-1.8-2C208,144.5,207.3,144.3,206.6,144.3z M205.4,234.3
			c-11.2-0.7-19.7-6.6-24.4-17.1c-2.6-5.7-2.9-12-1-18.6c1.6-5.4,3.9-11.1,7-17.4c2.6-5.3,5.4-10.7,8.1-15.9l0.1-0.2
			c1.6-3.1,3.2-6.3,4.8-9.5c0.8-1.5,1.5-3,2.2-4.5l0.1-0.1c0.5-1.1,1.1-2.3,1.6-3.4c0.5-1,1.2-1.7,2.1-1.9c0.7-0.2,1.5-0.1,2.2,0.3
			c0.5,0.3,1,0.9,1.3,1.4l2.1,4.2c5.7,11.4,11.6,23.2,17.3,34.9c2.2,4.5,3.9,9.1,5,13.9c1.6,6.9,0.6,13.6-3,19.9
			c-3.5,6.1-8.8,10.3-15.6,12.6C212.4,233.8,209.6,234.2,205.4,234.3z M206.6,146.6c-0.1,0-0.3,0-0.4,0.1c-0.6,0.2-1.1,0.7-1.4,1.4
			c-0.6,1.1-1.1,2.3-1.6,3.4l-0.1,0.1c-0.7,1.5-1.5,3-2.2,4.5c-1.6,3.2-3.2,6.3-4.8,9.5l-0.1,0.2c-2.7,5.2-5.4,10.6-8.1,15.9
			c-3.1,6.2-5.4,11.9-6.9,17.3c-1.8,6.4-1.5,12.4,1,17.9c4.6,10.2,12.7,15.9,23.5,16.5c4.1-0.1,6.8-0.4,9.5-1.3
			c6.6-2.2,11.6-6.3,15-12.1c3.5-6,4.5-12.5,2.9-19.1c-1.1-4.6-2.7-9.2-4.9-13.6c-5.7-11.7-11.6-23.5-17.3-34.9l-2.1-4.2
			c-0.2-0.4-0.6-0.8-0.9-1C207.2,146.7,206.9,146.6,206.6,146.6z"/>
            </g>
            <g>
                <path className="st0" d="M205.6,226.9C205.6,226.9,205.6,226.9,205.6,226.9c-9.3-0.5-16.2-5.4-20.1-14c-0.3-0.6,0-1.3,0.6-1.5
			c0.6-0.3,1.3,0,1.5,0.6c3.5,7.8,9.8,12.2,18.1,12.6c0.6,0,1.1,0.6,1.1,1.2C206.8,226.4,206.2,226.9,205.6,226.9z"/>
                <path className="st0" d="M205.6,227.4l-0.1,0c-9.4-0.5-16.5-5.5-20.5-14.3c-0.2-0.4-0.2-0.9,0-1.3s0.5-0.7,0.9-0.9
			c0.4-0.2,0.9-0.2,1.3,0c0.4,0.2,0.7,0.5,0.9,0.9c3.4,7.6,9.5,11.9,17.6,12.3c0.9,0.1,1.6,0.8,1.6,1.8
			C207.2,226.7,206.5,227.4,205.6,227.4z M186.6,211.8c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.3,0.2-0.3,0.4c-0.1,0.2-0.1,0.3,0,0.5
			c3.8,8.5,10.6,13.2,19.6,13.7l0,0.5v-0.5c0.3,0,0.6-0.3,0.7-0.6c0-0.4-0.3-0.7-0.6-0.7c-8.5-0.5-14.9-5-18.5-12.9
			c-0.1-0.2-0.2-0.3-0.4-0.3C186.7,211.8,186.6,211.8,186.6,211.8z"/>
            </g>
            <g>
                <defs>
                    <polygon id="SVGID_1_" points="210.8,145.1 203.7,144.8 177.2,201.4 177.4,208.1 170.9,211.3 152.8,215.6 152.7,238.6
				131.3,238.8 131.2,215.4 95.6,205.6 82.6,180.9 68.8,145.1 72.3,90.2 86.1,63 210.8,60.6 			"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_" style={{overflow:'visible'}}/>
                </clipPath>
                <g clipPath="url(#SVGID_2_)">
                    <g>
                        <path className="st0" d="M142,214.5c-38.1,0-65.8-30.1-65.8-71.6v-41.8c0-20.6,16.7-37.3,37.3-37.3h57c20.6,0,37.3,16.7,37.3,37.3
					V143C207.8,184.4,180.2,214.5,142,214.5z M113.5,66.3c-19.2,0-34.8,15.6-34.8,34.8V143c0,40,26.6,69.1,63.3,69.1h0
					c36.7,0,63.3-29,63.3-69.1v-41.8c0-19.2-15.6-34.8-34.8-34.8H113.5z"/>
                        <path className="st0" d="M142,215.4c-38.6,0-66.7-30.5-66.7-72.4v-41.8c0-21.1,17.1-38.2,38.2-38.2h57c21.1,0,38.2,17.1,38.2,38.2
					V143C208.7,184.9,180.7,215.4,142,215.4z M113.5,64.7c-20.1,0-36.5,16.4-36.5,36.5V143c0,41,27.3,70.7,65,70.7
					c37.6,0,65-29.7,65-70.7v-41.8c0-20.1-16.4-36.5-36.5-36.5H113.5z M142,212.9c-37.2,0-64.2-29.4-64.2-69.9v-41.8
					c0-19.7,16-35.7,35.7-35.7h57c19.7,0,35.7,16,35.7,35.7V143C206.2,183.5,179.2,212.9,142,212.9z M113.5,67.2
					c-18.7,0-34,15.2-34,34V143c0,39.5,26.3,68.2,62.5,68.2c36.2,0,62.5-28.7,62.5-68.2v-41.8c0-18.7-15.2-34-34-34H113.5z"/>
                    </g>
                    <g>
                        <path className="st0" d="M142,140.4c-19.4,0-35.2-15.8-35.2-35.2v-1.1c0-9.4,7.7-17.1,17.1-17.1h36.2c9.4,0,17.1,7.7,17.1,17.1v1.1
					C177.2,124.6,161.4,140.4,142,140.4z M123.9,89.5c-8.1,0-14.6,6.6-14.6,14.6v1.1c0,18,14.7,32.7,32.7,32.7s32.7-14.7,32.7-32.7
					v-1.1c0-8.1-6.6-14.6-14.6-14.6H123.9z"/>
                        <path className="st0" d="M142,141.3c-19.9,0-36.1-16.2-36.1-36.1v-1.1c0-9.9,8.1-18,18-18h36.2c9.9,0,18,8.1,18,18v1.1
					C178.1,125.1,161.9,141.3,142,141.3z M123.9,87.8c-9,0-16.2,7.3-16.2,16.2v1.1c0,18.9,15.4,34.4,34.4,34.4
					c18.9,0,34.4-15.4,34.4-34.4v-1.1c0-9-7.3-16.2-16.2-16.2H123.9z M142,138.8c-18.5,0-33.6-15.1-33.6-33.6v-1.1
					c0-8.5,6.9-15.5,15.5-15.5h36.2c8.5,0,15.5,6.9,15.5,15.5v1.1C175.6,123.7,160.5,138.8,142,138.8z M123.9,90.3
					c-7.6,0-13.7,6.2-13.7,13.7v1.1c0,17.6,14.3,31.9,31.9,31.9c17.6,0,31.9-14.3,31.9-31.9v-1.1c0-7.6-6.2-13.7-13.7-13.7H123.9z"
                        />
                    </g>
                    <g>
                        <g>
                            <path className="st0" d="M127.6,195.6c-1.4,0-2.8-0.7-3.6-1.9l-7.6-11.5c-0.9-1.3-0.9-3-0.2-4.4c0.7-1.4,2.2-2.2,3.8-2.2h13.9
						c1.5,0,2.9,0.8,3.7,2.1c0.8,1.3,0.8,2.9,0.1,4.2l-6.3,11.5c-0.7,1.3-2.1,2.1-3.6,2.2C127.7,195.6,127.6,195.6,127.6,195.6z
						 M120,178.1c-0.7,0-1.3,0.4-1.6,0.9c-0.3,0.6-0.3,1.3,0.1,1.8l7.6,11.5c0.4,0.5,0.9,0.8,1.6,0.8c0.6,0,1.2-0.4,1.5-0.9
						l6.3-11.5c0.3-0.6,0.3-1.2,0-1.8c-0.3-0.6-0.9-0.9-1.5-0.9H120z"/>
                            <path className="st0" d="M127.6,196.4c-1.7,0-3.3-0.9-4.3-2.3l-7.6-11.5c-1-1.6-1.1-3.6-0.2-5.3c0.9-1.7,2.6-2.7,4.5-2.7h13.9
						c1.8,0,3.5,0.9,4.4,2.5c0.9,1.6,1,3.5,0.1,5.1l-6.3,11.5c-0.9,1.6-2.5,2.6-4.3,2.7L127.6,196.4z M120,176.4
						c-1.3,0-2.4,0.7-3,1.8c-0.6,1.1-0.5,2.4,0.2,3.5l7.6,11.5c0.6,1,1.7,1.5,2.8,1.5v0.9l0.2-0.9c1.2,0,2.3-0.7,2.8-1.8l6.3-11.5
						c0.6-1.1,0.6-2.3-0.1-3.4c-0.6-1-1.7-1.7-2.9-1.7H120z M127.7,194c-1,0-1.8-0.4-2.3-1.2l-7.6-11.5c-0.5-0.8-0.6-1.8-0.1-2.7
						c0.5-0.9,1.3-1.4,2.3-1.4h13.9c1,0,1.8,0.5,2.3,1.3c0.5,0.8,0.5,1.8,0,2.6l-6.3,11.5C129.5,193.4,128.6,193.9,127.7,194z
						 M120,178.9c-0.4,0-0.6,0.2-0.8,0.5c-0.2,0.3-0.1,0.7,0,0.9l7.6,11.5c0.2,0.2,0.4,0.4,0.8,0.4c0.3,0,0.6-0.2,0.7-0.5l6.3-11.5
						c0.2-0.3,0.2-0.6,0-0.9c-0.2-0.3-0.4-0.5-0.8-0.5H120z"/>
                        </g>
                        <g>
                            <path className="st0" d="M133.9,170.4H120c-1.6,0-3-0.9-3.8-2.2c-0.7-1.4-0.7-3.1,0.2-4.4l7.6-11.5c0.8-1.3,2.3-1.9,3.7-1.9
						c1.5,0.1,2.8,0.9,3.6,2.2l6.3,11.5c0.7,1.3,0.7,2.9-0.1,4.2C136.8,169.6,135.4,170.4,133.9,170.4z M127.6,152.9
						c-0.6,0-1.1,0.3-1.5,0.8l-7.6,11.5c-0.4,0.6-0.4,1.2-0.1,1.8c0.3,0.6,0.9,0.9,1.6,0.9h13.9c0.6,0,1.2-0.3,1.5-0.9
						c0.3-0.6,0.3-1.2,0-1.8l-6.3-11.5C128.8,153.2,128.3,152.9,127.6,152.9C127.6,152.9,127.6,152.9,127.6,152.9z"/>
                            <path className="st0" d="M133.9,171.2H120c-1.9,0-3.6-1-4.5-2.7c-0.9-1.7-0.8-3.7,0.2-5.3l7.6-11.5c1-1.5,2.6-2.3,4.5-2.3
						c1.8,0.1,3.4,1.1,4.3,2.7l6.3,11.5c0.9,1.6,0.9,3.5-0.1,5.1C137.4,170.3,135.7,171.2,133.9,171.2z M127.7,151.2
						c-1.2,0-2.3,0.6-2.9,1.5l-7.6,11.5c-0.7,1-0.8,2.4-0.2,3.5c0.6,1.1,1.7,1.8,3,1.8h13.9c1.2,0,2.3-0.6,2.9-1.7
						c0.6-1,0.6-2.3,0.1-3.4l-6.3-11.5C130,151.9,128.9,151.3,127.7,151.2C127.7,151.2,127.7,151.2,127.7,151.2z M133.9,168.8H120
						c-1,0-1.9-0.5-2.3-1.4c-0.5-0.9-0.4-1.9,0.1-2.7l7.6-11.5c0.5-0.8,1.3-1.2,2.2-1.2l0.1,0c1,0,1.8,0.5,2.3,1.4l6.3,11.5
						c0.5,0.8,0.4,1.8,0,2.6C135.7,168.3,134.9,168.8,133.9,168.8z M127.6,152.9v0.9c-0.3,0-0.6,0.1-0.8,0.4l-7.6,11.5
						c-0.2,0.3-0.2,0.6,0,0.9c0.2,0.3,0.5,0.5,0.8,0.5h13.9c0.3,0,0.6-0.2,0.8-0.5c0.2-0.3,0.2-0.6,0-0.9l-6.3-11.5
						c-0.2-0.3-0.4-0.5-0.8-0.5L127.6,152.9z"/>
                        </g>
                    </g>
                    <g>
                        <path className="st0" d="M155,174.2c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7c5.9,0,10.7,4.8,10.7,10.7S160.9,174.2,155,174.2z
					 M155,155.3c-4.5,0-8.2,3.7-8.2,8.2c0,4.5,3.7,8.2,8.2,8.2c4.5,0,8.2-3.7,8.2-8.2C163.2,159,159.5,155.3,155,155.3z"/>
                        <path className="st0" d="M155,175c-6.4,0-11.5-5.2-11.5-11.5c0-6.4,5.2-11.5,11.5-11.5c6.4,0,11.5,5.2,11.5,11.5
					C166.5,169.9,161.3,175,155,175z M155,153.7c-5.4,0-9.8,4.4-9.8,9.8c0,5.4,4.4,9.8,9.8,9.8c5.4,0,9.8-4.4,9.8-9.8
					C164.8,158.1,160.4,153.7,155,153.7z M155,172.6c-5,0-9-4.1-9-9c0-5,4.1-9,9-9c5,0,9,4.1,9,9C164,168.5,160,172.6,155,172.6z
					 M155,156.2c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3c4,0,7.3-3.3,7.3-7.3C162.3,159.5,159,156.2,155,156.2z"/>
                    </g>
                    <g>
                        <path className="st0"
                              d="M151.3,237.3h-18.6V212h18.6V237.3z M135.2,234.8h13.7v-20.3h-13.7V234.8z"/>
                        <path className="st0" d="M152.2,238.2h-20.3v-27h20.3V238.2z M133.6,236.5h16.9v-23.5h-16.9V236.5z M149.7,235.7h-15.4v-22h15.4
					V235.7z M136.1,234H148v-18.6h-11.9V234z"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);