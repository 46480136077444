import React from "react";
import './SideEffectRisk.scss'

export const SideEffectRisk = (props) => (
    <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}>

        <g>
            <path className="st0" d="M111.2,192.5c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2
		C115.4,194.3,113.5,192.5,111.2,192.5z"/>
            <path className="st0" d="M111.2,188.4c2.2,0,4-1.8,4-4v-41.9c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4v41.9C107.2,186.7,109,188.4,111.2,188.4
		z"/>
            <path className="st0" d="M205.9,87.4v-16c0-2.6-2.1-4.6-4.6-4.6h-62.8c-2.6,0-4.7,2.1-4.7,4.6v16c0,2.6,2.1,4.6,4.7,4.6h62.8
		C203.8,92.1,205.9,90,205.9,87.4z"/>
            <path className="st0" d="M148.8,8.9C71.9,8.9,9,71.2,9.3,147c0.1,25,6.7,48.4,18.4,68.5c12.6,21.8,31,39.9,53.2,52.1
		c20.3,11.2,43.7,17.5,68.7,17.4c24.3-0.1,47.1-6.2,66.9-17c22.2-12.1,40.6-30,53.3-51.7c11.9-20.4,18.7-44.2,18.7-69.6
		C288.3,70.4,226,8.9,148.8,8.9z M161.6,212c-3,4.9-8.3,7.8-14,7.8H74.5c-5.7,0-11-2.9-14-7.8c-3.1-4.9-3.3-11-0.7-16.1l36.5-73
		c2.8-5.6,8.6-9.2,14.8-9.2c6.3,0,12,3.6,14.8,9.2l36.5,73C164.9,201,164.7,207.1,161.6,212z M221.7,141.8L221.7,141.8l0,57.3h0v6.9
		c0,7.6-6.2,13.8-13.8,13.8h-41.9c1.7-1.3,3.1-2.9,4.3-4.7c3.3-5.2,3.5-11.7,0.8-17.1l-0.5-1l18.7,0H217v-52.2h-72.6l-12.1-24.3
		c-0.4-0.8-0.9-1.6-1.4-2.4l8-10.7V96.7h-0.4c-5.1,0-9.3-4.2-9.3-9.3v-16c0-5.1,4.2-9.3,9.3-9.3h62.8c5.1,0,9.3,4.2,9.3,9.3v16
		c0,5.1-4.2,9.3-9.3,9.3H201v10.5l18,23.9c1.8,2.4,2.8,5.3,2.8,8.3V141.8z"/>
        </g>
</svg>

);