import React from "react";

export const Success = (props) => (
    <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         height={`${props.size}px`}
         width={`${props.size}px`}
         style={{color:'#00AB66'}}
         x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve">
        <path className="st0" d="M10,1.3c-4.8,0-8.8,3.9-8.8,8.7c0,1.6,0.4,3,1.2,4.3c0.8,1.4,2,2.5,3.4,3.3c1.3,0.7,2.8,1.1,4.3,1.1
	c1.5,0,3-0.4,4.2-1.1c1.4-0.8,2.6-1.9,3.4-3.3c0.8-1.3,1.2-2.8,1.2-4.4C18.8,5.2,14.9,1.3,10,1.3z M13.6,8.3l-4.2,4.2
	c-0.1,0.1-0.3,0.2-0.5,0.2s-0.3-0.1-0.5-0.2l-2.1-2.1c-0.3-0.3-0.3-0.7,0-0.9c0.3-0.3,0.7-0.3,0.9,0l1.6,1.6l3.7-3.7
	c0.3-0.3,0.7-0.3,0.9,0C13.8,7.7,13.8,8.1,13.6,8.3z"/>
    </svg>
);