import React from "react";

export const Warning = (props) => (
    <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}
         style={{color:'#ffc107'}}>
        <path className="st0" d="M18.4,14.8l-6-12C12,1.9,11,1.3,10,1.3S8,1.9,7.6,2.8l-6,12c-0.4,0.8-0.4,1.8,0.1,2.6c0.5,0.8,1.4,1.3,2.3,1.3
            h12c0.9,0,1.8-0.5,2.3-1.3C18.8,16.6,18.9,15.6,18.4,14.8z M9.4,6.4C9.4,6,9.7,5.7,10,5.7s0.6,0.3,0.6,0.6v6.5
            c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6V6.4z M10,15.5c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.6,0.7-0.6s0.7,0.3,0.7,0.6
            C10.7,15.2,10.4,15.5,10,15.5z"/>
    </svg>
    );