import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../../axios/axiosBack";
import {toast} from "react-toastify";
import Text from "../../assets/strings";


export const fetchMedication = createAsyncThunk(
    'med/fetchMed', async ({hash, drug_id, lang}) => {
        const response = await axios.get(`/${hash}/medication/${drug_id}?lang=${lang}`);
        return response.data;
    });

/*export const addNewPost = createAsyncThunk(
    'posts/addNewPost',
    async post => {
        const response = await client.post('fakeApi/posts', {post: post});
        return response.post
    });*/

export const addMedToFavorite = createAsyncThunk('med/AddMedToFav',
    async ({hash, drug_id}) => {
        const response = await axios.post(`/${hash}/medication/add/${drug_id}`);
        return response.status;
    });

export const removeMedFromFavorite = createAsyncThunk('med/removeMedFromFavorite',
    async ({hash, drug_id}) => {
        const response = await axios.delete(`/${hash}/medication/remove/${drug_id}`);
        return response.status;
    });


export const addMedEffect = createAsyncThunk('med/addMedEffect', async ({hash, drug_id}) => {
    const response = await axios.post(`/${hash}/effect/add/${drug_id}`);
    return response.status;
} );

export const removeMedEffect = createAsyncThunk('med/addMedEffect', async ({hash, drug_id}) => {
    const response = await axios.delete(`/${hash}/effect/remove/${drug_id}`);
    return response.status;
} );


const initialState = {
    status: 'idle',
    item: {},
    error: null
};

const medicationSlice = createSlice({
    name: 'medication',
    initialState,
    reducers: {
        medicationStateReset: () => initialState,
        contextAdded: (state, action) => {
            const {context,geneContext} = action.payload;
            state.item = {
                            ...state.item,
                            context,
                            geneContext
                        }
        }
    },
    extraReducers: {
        [fetchMedication.pending]: state => {
            state.status = 'loading'
        },
        [fetchMedication.fulfilled]: (state, action) => {
            state.status = 'completed';
            state.item = {...action.payload[0]}
        },
        [fetchMedication.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message
        },
        [addMedToFavorite.fulfilled]: state => {
            state.item.isFavorite = !state.item.isFavorite;
        },
        [removeMedFromFavorite.fulfilled]: state => {
            state.item.isFavorite = !state.item.isFavorite;
        },
        [addMedEffect.fulfilled]: state => {
            state.item.hasEffect = !state.item.hasEffect;
        },
        [removeMedEffect.fulfilled]: state => {
            state.item.hasEffect = !state.item.hasEffect;
        }
    }
});

export const {medicationStateReset, contextAdded} = medicationSlice.actions;

export const selectMedication = state => state.entities.medication.item;
export const selectFetchStatus = state => state.entities.medication.status;
export const selectMedicationError = state => state.entities.medication.error;

export default medicationSlice.reducer