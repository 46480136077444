import React, {useEffect, useState} from 'react';
import {KineticsTable} from "../layout/DataTable/KineticsTable";
import GoBack from "../../components/layout/goBack/goBack";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'
import {LowEffect} from "../layout/icons/LowEffect";
import {SideEffectRisk} from "../layout/icons/SideEffectRisk/SideEffectRisk";
import {MetaAltered} from "../layout/icons/MetaAltered";
import {GlobalScore} from "../layout/GlobalScore/GlobalScore";
import {GlobalScoreFooter} from "../layout/GlobalScoreFooter/GlobalScoreFooter";
import Text from '../../assets/strings';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {DynamicsTable} from "../layout/DataTable/DynamicsTable";
import {AiOutlineInfoCircle} from "react-icons/ai";
import Markdown from 'react-markdown';
import {ToggleButton} from "../layout/ToggleButton";
import {useDispatch, useSelector} from "react-redux";
import {selectHash, selectLanguage, titleUpdated} from "../../feature/app/appSlice";
import {
    addMedEffect,
    addMedToFavorite,
    fetchMedication, removeMedEffect, removeMedFromFavorite,
    selectFetchStatus,
    selectMedication,
    selectMedicationError
} from "../../feature/medication/medicationSlice";
import {LoadingDNA} from "../layout/LoadingDNA";

export const Medication = (props) => {
    const hash = useSelector(state => selectHash(state));
    const lang = useSelector(state => selectLanguage(state));
    const state = useSelector(state => selectMedication(state));
    const fetchStatus = useSelector(state => selectFetchStatus(state));
    const error = useSelector(state => selectMedicationError(state));
    const dispatch = useDispatch();
    const drug_id = props.match.params.id;
    let content;
    
    useEffect(() => {
        if (state !== undefined) {
            dispatch(titleUpdated(state?.name))
        }
    }, [state]);
    
    useEffect(() => {
        dispatch(
            fetchMedication({hash, drug_id, lang})
        );
    }, [lang, hash]);
    
    const removeMed = async (hash, drug_id) => {
        const response = await dispatch(removeMedFromFavorite({hash, drug_id}));
        if (response.payload === 200) {
            toast.info(Text[lang].web.medication["medication-deleted"], {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            console.error(response)
        }
    };
    
    const addMed = async (hash, drug_id) => {
        const response = await dispatch(addMedToFavorite({hash, drug_id}));
        if (response.payload === 200) {
            toast.info(Text[lang].web.medication["medication-added"], {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            console.error(response)
        }
    };
    
    const addEffect = async (hash, drug_id) => {
        const response = await dispatch(addMedEffect({hash, drug_id}));
        if (response.payload === 200) {
            toast.info(Text[lang].web.medication["effect-added"], {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    };
    
    const removeEffect = async (hash, drug_id) => {
        const response = await dispatch(removeMedEffect({hash, drug_id}));
        if (response.payload === 200) {
            toast.info(Text[lang].web.medication["effect-deleted"], {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    };
    
    const handleDrug = (isFavorite) => {
        
        const {drug_id} = props.location.state;
        
        if (isFavorite) {
            removeMed(hash, drug_id)
        } else {
            addMed(hash, drug_id)
        }
        
    };
    
    const handleEffect = (hasEffect) => {
        
        const {drug_id} = props.location.state;
        
        if (hasEffect) {
            removeEffect(hash, drug_id)
        } else {
            addEffect(hash, drug_id)
        }
        
    };
    
    
    if (fetchStatus === 'loading') {
        content = <LoadingDNA/>
    } else if (fetchStatus === 'completed') {
        content = (
            <>
                <div className="inner-content">
                    <div className="medication__header">
                        <div className="inner-header">
                            <div className="title">
                                <GoBack history={props.history}/>
                                <h3>{state.name}</h3>
                            </div>
                            <div className="has-effect">
                                <label>
                                    <input
                                        type="checkbox"
                                        className="filled-in"
                                        checked={state.hasEffect}
                                        onChange={() => handleEffect(state.hasEffect)}
                                        name="side-effect"/>
                                    <span>{Text[lang].web['side-effects']}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="medication__score">
                        <div className="inner-score">
                            <div className="info-container">
                                <OverlayTrigger
                                    key='top'
                                    placement='top'
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {Text[lang].web.info}
                                        </Tooltip>
                                    }
                                >
                                    <AiOutlineInfoCircle size={20}/>
                                </OverlayTrigger>
                            </div>
                            {
                                state ?
                                    <ul className="list">
                                        <li className={state.metabolizationLevel === false || state.metabolizationLevel === null ? 'disabled-score' : 'score'}>
                                            <div className="icon-container">
                                                <MetaAltered size={60}/>
                                            </div>
                                            <div className="name">
                                                <span>{Text[lang].web.medication.metabolization}</span>
                                            </div>
                                        </li>
                                        
                                        <li className={state.hasLowEfficacy === 0 ? 'disabled-score' : 'score'}>
                                            <div className="icon-container">
                                                <LowEffect size={60}/>
                                            </div>
                                            <div className="name">
                                                <span>{Text[lang].web.medication.efficacy}</span>
                                            </div>
                                        </li>
                                        
                                        <li className={state.hasAdverse !== 0 ? 'score' : 'disabled-score'}>
                                            <div className="icon-container">
                                                <SideEffectRisk size={60}/>
                                            </div>
                                            <div className="name">
                                                <span>{Text[lang].web.medication.adverse}</span>
                                            </div>
                                        </li>
                                    </ul>
                                    : null
                            }
                        
                        </div>
                    </div>
                    
                    {
                        state.drugRecommendations ?
                            <div className="medication__recommendations">
                                <h5>{Text[lang].web.medication.title}</h5>
                                <div className="recommendation">
                                    {state.drugRecommendations.map((r, i) => <Markdown source={r} key={i} escapeHtml={false}/>)}
                                </div>
                            </div>
                            : null
                    }
                    
                    {
                        state.pharmacokinetics !== null ?
                            <div className="medication__table-container">
                                <KineticsTable
                                title={Text[lang].web.medication.pharmacokinetics}
                                drug={drug_id}
                                hash={hash}
                                data={state.pharmacokinetics.genes}
                                drugClass={state.drugClass}/>
                            </div>
                            : null
                    }
                    
                    {
                        state.pharmacodynamics !== null ?
                            <div className="medication__table-container">
                                <DynamicsTable
                                title={Text[lang].web.medication.pharmacodynamics}
                                drug={drug_id}
                                hash={hash}
                                data={state.pharmacodynamics.genes}
                                drugClass={state.drugClass}/>
                            </div>
                            : null
                    }
                
                </div>
                
                <GlobalScoreFooter
                isFavorite={state.isFavorite}
                click={handleDrug}
                level={state.globalLevel}
                lang={lang}/>
                
                <div className="right-side-container">
                    
                    <GlobalScore
                    isFavorite={state.isFavorite}
                    click={handleDrug}
                    level={state.globalLevel}
                    lang={lang}/>
                    
                    <div className="button-container">
                        
                        <div className="info-container">
                            <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {Text[lang].web.medication['drug-info-icon']}
                                </Tooltip>
                            }>
                                <AiOutlineInfoCircle size={20}/>
                            </OverlayTrigger>
                        </div>
                        
                        <ToggleButton state={state.isFavorite} click={() => handleDrug(state.isFavorite)}/>
                    </div>
                    
                    <div className="side-effect-container">
                        <label>
                            <input
                                type="checkbox"
                                className="filled-in"
                                checked={state.hasEffect}
                                onChange={() => handleEffect(state.hasEffect)}
                                name="side-effect"/>
                            <span>{Text[lang].web['side-effects']}</span>
                        </label>
                    </div>
                </div>
            </>
        )
    } else if (fetchStatus === 'failed') {
        content = <div>{error}</div>
    }
    
    return (
        <>
            {content}
        </>
    )
};