import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showUserMedInstructions: localStorage.getItem("userMedInst") || "0",
    showCategoryInstructions: localStorage.getItem("catInst") || "0"
};

const instructionsSlice = createSlice({
    name:'instructions',
    initialState,
    reducers: {
        userMedsInstUpdated: state => {
            state.showUserMedInstructions = "1";
            localStorage.setItem('userMedInst', "1");
        },
        categoryInstUpdated: state => {
            state.showCategoryInstructions = "1";
            localStorage.setItem('catInst', "1");
        }
    }
});

export const {userMedsInstUpdated,categoryInstUpdated} = instructionsSlice.actions;

export default instructionsSlice.reducer;