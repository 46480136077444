import React from "react";
import instructions from "../../assets/help";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../feature/app/appSlice";
import "./Installation.scss";

export const Installation = () => {
  const lang = useSelector((state) => selectLanguage(state));

  return (
    <div className="inner-content">
      <div className="medication__header">
        <div className="inner-header">
          <div className="title">
            <h3>{instructions[lang].installationTitle}</h3>
          </div>
        </div>
      </div>

      <div className="help-container">
        <div className={"instructions"}>
          <h5>{instructions[lang]["how-to-make-shortcut-title-android"]}</h5>
          <ul>
            <li>{instructions[lang]["how-to-make-shortcut-step-one"]}</li>
            <li>{instructions[lang]["how-to-make-shortcut-step-two"]}</li>
            <li>{instructions[lang]["how-to-make-shortcut-step-three"]}</li>
          </ul>
          {/*            <p>{instructions[lang]["watch-video"]}</p>
            <div className="video-container">
                <iframe src="https://www.youtube.com/embed/x05fsRH_3tg" frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen title="android chrome shortcut"/>
            </div>*/}

          <h5>{instructions[lang]["how-to-make-shortcut-title-ios"]}</h5>
          <ul>
            <li>{instructions[lang]["how-to-make-shortcut-ios-step-one"]}</li>
            <li>{instructions[lang]["how-to-make-shortcut-ios-step-two"]}</li>
            <li>{instructions[lang]["how-to-make-shortcut-ios-step-three"]}</li>
          </ul>
          {/*            <p>{instructions[lang]["watch-video"]}</p>
            <div className="video-container">
                <iframe src="https://www.youtube.com/embed/dSIarEvDZbk" frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen title="ios safari shortcut"/>
            </div>*/}
        </div>
      </div>
    </div>
  );
};
