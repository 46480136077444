import React, { useEffect } from "react";
import Text from "../../assets/strings";
import "./Ref.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage, titleUpdated } from "../../feature/app/appSlice";
import ReactMarkdown from "react-markdown";

export const Ref = () => {
  const lang = useSelector((state) => selectLanguage(state));
  const dispatch = useDispatch();

  const title = Text[lang].web.ref.title;

  useEffect(() => {
    dispatch(titleUpdated(title));
  }, [lang]);

  let asd = "1. PharmGKB - A Pharmacogenomics Knowledgebase. Disponível em https://www.pharmgkb.org/.\n2. CPIC - Clinical Pharmacogenetics Implementation Consortium. Disponível em https://cpicpgx.org/.\n\n3. DPWG - Dutch Pharmacogenetics Working Group. Disponível em https://www.knmp.nl/. "

  return (
    <div className="inner-content">
      <div className="medication__header">
        <div className="inner-header">
          <div className="title">
            <h3 style={{marginBottom: '1em'}}>{Text[lang].web.ref.title}</h3>
          </div>
        </div>
      </div>
      {/* <div
        className="reference-container"
        dangerouslySetInnerHTML={{ __html: Text[lang].web.ref.content }}
      /> */}
      <h5 style={{marginBottom: '.8em'}}>{Text[lang].web.ref.subtitle1}</h5>
      <ReactMarkdown>
        {Text[lang].web.ref.content1}
      </ReactMarkdown>
      <h5 style={{marginBottom: '.8em'}}>{Text[lang].web.ref.subtitle2}</h5>
      <ReactMarkdown>
        {Text[lang].web.ref.content2}
      </ReactMarkdown>
      <h5 style={{marginBottom: '.8em'}}>{Text[lang].web.ref.subtitle3}</h5>
      <ReactMarkdown>
        {Text[lang].web.ref.content3}
      </ReactMarkdown>
    </div>
  );
};
