import React, { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';

const Modal = ({ children, isOpen = false, close, elementId = 'root' }) => {
    if (isOpen === false) {
        return null;
    }
    return createPortal(
        <div className="modal-wrapper" onClick={close}>

            <div className="modal-container">
                <div className="cross" role="button" tabIndex="0" data-target="dismiss" aria-label="Close">
                    <svg className="a-qd-va HfXy2c" x="0px" y="0px" width="10px" height="10px" viewBox="0 0 10 10"
                         focusable="false" fill="#FFFFFF" onClick={close}>
                        <polygon className="a-s-fa-Ha-pa"
                                 points="10,1.01 8.99,0 5,3.99 1.01,0 0,1.01 3.99,5 0,8.99 1.01,10 5,6.01 8.99,10 10,8.99 6.01,5 "></polygon>
                    </svg>
                </div>
                <div className="text">
                    {children}
                </div>
            </div>
        </div>,
        document.getElementById(elementId)
    );
};

const useModal = elementId => {
    const [isOpen, setOpen] = useState(false);
    const open = useCallback(() => setOpen(true), [setOpen]);
    const close = useCallback(() => setOpen(false), [setOpen]);

    const ModalWrapper = ({ children }) => (
        <Modal isOpen={isOpen} close={close} elementId={elementId}>
            {children}
        </Modal>
    );

    return [ModalWrapper, open, close];
};

export default useModal;