import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../../axios/axiosBack";


export const fetchUserMeds = createAsyncThunk(
    'userMeds/fetchUserMeds', async ({hash,str}) => {
        const response = await axios.get(`/${hash}/myMeds${str}`);
        return response.data;
    });

const userMedicationSlice = createSlice({
    name: 'userMeds',
    initialState: {
        list: {},
        error: null,
        status: 'idle'
    },
    reducers: {
    
    },
    extraReducers: {
        [fetchUserMeds.pending]: state => {
            state.status = 'loading';
        },
        [fetchUserMeds.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.list = {...action.payload};
        },
        [fetchUserMeds.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message
        }
    }
})


export const selectUserMeds = state => state.entities.userMeds.list;


export default userMedicationSlice.reducer

