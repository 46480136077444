import React, {useEffect, useState} from "react";
import config from "../../config/business/config";
import {useDispatch, useSelector} from "react-redux";
import {selectHash, selectLanguage} from "../../feature/app/appSlice";
import {fetchMedication, selectFetchStatus, selectMedication} from "../../feature/medication/medicationSlice";

export const MetabolismLevel = (props) => {
    const hash = useSelector(state => selectHash(state));
    let lang = useSelector(state => selectLanguage(state));
    const geneFolders = config.geneFolders;
    const [path, setPath] = useState(null);
    const medication = useSelector(state => selectMedication(state));
    const fetchStatus = useSelector(state => selectFetchStatus(state));
    const dispatch = useDispatch();
    const drug_id = props.match.params.id;
    
    
    useEffect(() => {
        if(fetchStatus === 'idle'){
            dispatch(
                fetchMedication({hash, drug_id, lang})
            );
        }
    },[]);
    
    useEffect(() => {
        lang = lang !== 'pt_PT' && lang !== 'pt_BR' && lang !== 'es_ES' && lang !== 'fr_FR' && lang !== 'de_DE' ? 'en_GB' : lang;

        geneFolders.forEach((folder, i) => {
            if(folder.includes(props.gene)) {
                import(`../../assets/img/genes/${lang}/${i}/${props.level}.svg`).then(path => setPath(path));
            } else {
                return null;
            }
        });
        
    }, [lang, props, medication]);
    
    return path && <img className="reveal-image" src={path.default} alt="" style={{width: '350px', height: '56px'}}/>;

};