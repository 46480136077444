import React from "react";
import {NavLink} from "react-router-dom";
import Text from '../../assets/strings';
import {Favorite} from "./icons/Favorite";
import {Psychiatry} from "./icons/Psychiatry/Psychiatry";
import {PainManagement} from "./icons/PainManagement";
import {Oncology} from "./icons/Oncology";
import {Diabetes} from "./icons/Diabetes/Diabetes";
import {Cardiovascular} from "./icons/Cardiovascular";
import {useSelector} from "react-redux";
import {selectHash, selectHashIsValid, selectLanguage, selectTitle} from "../../feature/app/appSlice";

export const Sidebar = () => {
    const hashIsValid = useSelector(state=>selectHashIsValid(state));
    const hash = useSelector(state => selectHash(state));
    const lang = useSelector(state => selectLanguage(state));
    const menu = Text[lang].menu;

    return(
    <div className={hashIsValid ? 'sidebar' : 'sidebar disabled-link'} data-testid="sidebar">
        <ul className="sidebar__generic">
            <li>
                <NavLink to={`/${hash}/${menu[0].id}`} >
                    <Favorite size={40} sidebar={true}/>
                    {menu[0].name}
                </NavLink>
            </li>

            <li>
                <NavLink to={`/${hash}/${menu[1].id}`} >
                    <Psychiatry size={40}/>
                    {menu[1].name}
                </NavLink>
            </li>

            <li>
                <NavLink to={`/${hash}/${menu[2].id}`} >
                    <PainManagement size={40}/>
                    {menu[2].name}
                </NavLink>
            </li>

            <li>
                <NavLink to={`/${hash}/${menu[3].id}`} >
                    <Oncology size={40}/>
                    {menu[3].name}
                </NavLink>
            </li>

            <li>
                <NavLink to={`/${hash}/${menu[4].id}`} >
                    <Diabetes size={40}/>
                    {menu[4].name}
                </NavLink>
            </li>

            <li>
                <NavLink to={`/${hash}/${menu[5].id}`} >
                    <Cardiovascular size={40}/>
                    {menu[5].name}
                </NavLink>
            </li>
        </ul>
    </div>
)};