import React from "react";
import {Danger} from "../icons/Danger";
import {Warning} from "../icons/Warning";
import {Success} from "../icons/Success";

export const GlobalScoreIcon = (props) => {
    switch(props.level) {
        case 1:
            return <Danger size={props.size}/>;
        case 2:
            return <Warning size={props.size}/>;
        case 3:
            return <Success size={props.size}/>;
        default:
            return null;
    }
};
