import React, {useEffect, useState} from "react";
import Alert from 'react-bootstrap/Alert';
import GoBack from "../layout/goBack/goBack";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {GuideLines} from "../layout/icons/GuideLines";
import {DrugLabels} from "../layout/icons/DrugLabels";
import Text from "../../assets/strings";
import {EvidenceLevel} from "../layout/EvidenceLevel";
import {MetabolismLevel} from "../MetabolismLevel/MetabolismLevel";
import Markdown from "react-markdown";
import {useDispatch, useSelector} from "react-redux";
import {selectHash, selectLanguage, titleUpdated} from "../../feature/app/appSlice";
import {
    fetchMedication,
    selectFetchStatus,
    selectMedication,
    selectMedicationError
} from "../../feature/medication/medicationSlice";
import {LoadingDNA} from "../layout/LoadingDNA";

export const Gene = (props) => {
    const hash = useSelector(state => selectHash(state));
    const lang = useSelector(state => selectLanguage(state));
    const fetchStatus = useSelector(state => selectFetchStatus(state));
    const data = useSelector(state => selectMedication(state));
    const error = useSelector(state => selectMedicationError(state));
    const [state, setState] = useState(data || {});
    const dispatch = useDispatch();
    const drug_id = props.match.params.id;
    const gene = props.match.params.gene;
    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    let context;
    if(urlParams.get('ctx') === 'phdmcs') {
        context = 'pharmacodynamics';
    }else if(urlParams.get('ctx') === 'phkcs') {
        context = 'pharmacokinetics';
    }else {
        content = null;
    }
    
    let geneContext;
    if(urlParams.get('gnctx') === 'efcy') {
        geneContext = 'efficacy';
    }else if(urlParams.get('gnctx') === 'advrs') {
        geneContext = 'adverse';
    }else {
        geneContext = null;
    }
    
    const rs = urlParams.get('rs');
    
    useEffect(() => {
        if(fetchStatus === 'idle'){
            dispatch(
                fetchMedication({hash, drug_id, lang})
            );
        }
        if(fetchStatus === 'completed'){
            
            if (context === 'pharmacodynamics') {
                
                data[context].genes.forEach(g => {
                    
                    if (g.gene === gene && g.rs === rs) {
                        
                        if (g[geneContext]) {
                            
                            setState({
                                context: 'pharmacodynamics',
                                detailedDescription: g.detailedDescription,
                                consequence: g[geneContext].consequence,
                                phenotype: g[geneContext].text,
                                evidenceLevel: g[geneContext].evidenceLevel,
                                guidelines: g[geneContext].guidelines,
                                drugLabel: g[geneContext].drugLabel,
                                geneRecommendation: g.geneRecommendation,
                                adverseRecommendation: geneContext === 'adverse' ? g['adverse'].recommendation : null,
                                gene: g.gene,
                                rs: g.rs
                            })
                        }
                    }
                });
            
            }
            else {
    
                data[context].genes.forEach(g => {
                    if (g.gene === gene) {
                        setState({
                            detailedDescription: g.detailedDescription,
                            consequence: g.pharmacokineticConsequence,
                            phenotype: g.phenotype,
                            evidenceLevel: g.evidenceLevel,
                            guidelines: g.guidelines,
                            drugLabel: g.drugLabel,
                            geneRecommendation: g.geneRecommendation,
                            gene: g.gene,
                            metabolismLevel: g.metabolismLevel,
                            rs: g.rs
                        })
                    }
                });
            }
            dispatch(titleUpdated(state.gene))
        }
        
        return () => setState({})
        
    }, [dispatch, fetchStatus]);
    
    
    useEffect(() => {
        dispatch(
            fetchMedication({hash, drug_id, lang})
        );
    
        return () => setState({})
    },[lang]);

    let content;
    
    if(fetchStatus === 'loading') {
        content = <LoadingDNA/>
    } else if (fetchStatus === 'completed') {
        content = (
            <div className="inner-content">
        
                <div className="gene__header">
                    <div className="inner-header">
                        <div className="title">
                            <GoBack history={props.history}/>
                            <h3>
                                <i>{state.gene}</i>
                            </h3>
                        </div>
                    </div>
                </div>
        
                <div className="gene__text-container">
                    <div className="test">
                        <Markdown source={state.detailedDescription}/>
                    </div>
            
                    <div className="results">
                
                        <h5>{Text[lang].web.gene["your-results"]}</h5>
                
                        {
                            state.rs !== null ?
                                <small>{state.rs}</small>
                                : null
                        }
                        {
                            context !== 'pharmacodynamics' ?
                                <MetabolismLevel {...props} gene={state.gene} level={state.metabolismLevel} drugClass={state.drugClass}/>
                                : null
                        }
                
                        <p className="phenotype"><b>{state.phenotype}</b></p>
                
                        <div className="gene-figure-container" key="iconsWithToolTip">
                            {
                                state.evidenceLevel !== null ?
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                {Text[lang].web["evidence-level"][state.evidenceLevel]}
                                            </Tooltip>
                                        }
                                    >
                                        <span style={{cursor: 'pointer'}}>
                                            <EvidenceLevel level={state.evidenceLevel} size={30}/>
                                        </span>
                                    </OverlayTrigger>
                                    :
                                    null
                            }
                    
                            {
                                state.guidelines === 1 ?
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {Text[lang].web.guidelines}
                                            </Tooltip>
                                        }
                                    >
                                        <span><GuideLines size={30}/></span>
                                    </OverlayTrigger>
                                    : null
                            }
                    
                            {
                                state.drugLabel === 1 ?
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {Text[lang].web['drug-label']}
                                            </Tooltip>
                                        }
                                    >
                                        <span><DrugLabels size={30}/></span>
                                    </OverlayTrigger>
                                    : null
                            }
                        </div>
                
                
                        {
                            state.consequence !== undefined ?
                                <div className="mb-0">
                                    <Markdown source={state.consequence}/>
                                </div>
                                : null
                        }
                
                
                        {state.hasOwnProperty('geneRecommendation') && state?.geneRecommendation !== undefined && state?.geneRecommendation !== null ?
                            <>
                                <Alert variant="primary" className="gene_recommendation">
                                    <div className="mb-0">
                                        <Markdown source={state.geneRecommendation} escapeHtml={false}/>
                                    </div>
                                </Alert>
                            </>
                            : null
                        }
                        {state.hasOwnProperty('adverseRecommendation') && state?.adverseRecommendation !== undefined && state?.adverseRecommendation !== null ?
                            <>
                                <Alert variant="primary" className="gene_recommendation">
                                    <div className="mb-0">
                                        <Markdown source={state.adverseRecommendation} escapeHtml={false}/>
                                    </div>
                                </Alert>
                            </>
                            : null
                        }
                        <span className="note">{Text[lang].web.gene.note}</span>
            
                    </div>
                </div>
            </div>
        )
    } else if( fetchStatus === 'failed') {
        content = <div>{error}</div>
    }
    return (
        <>
            {content}
        </>
    );
};