import React from "react";

export const Cardiovascular = (props) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}>

            <g>
                    <path className="st0" d="M186.9,80.8c-15.7,0-30.3,8.5-38.1,22c-7.9-13.5-22.4-22-38.2-22c-24.4,0-44.2,19.8-44.2,44.2
			c0,36.3,49.6,76.6,71,92.2c3.3,2.4,7.1,3.6,10.9,3.6c4.1,0,8.2-1.4,11.5-4c21.4-17.1,71.3-60.2,71.3-91.7
			C231.1,100.6,211.3,80.8,186.9,80.8z M157.2,213.5c-5,4-12.2,4.2-17.4,0.4c-13.8-10.1-39.6-30.7-55.5-53.4H106l0.8-2.1l13.3-35.5
			c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l15,35.5l9.3,21.9c0.7,1.6,2.2,2.7,3.9,2.7c0.1,0,0.1,0,0.2,0c1.7,0,3.2-0.9,3.9-2.4
			l11.4-22.2l11.9-23.2c0,0,0.1-0.1,0.2-0.1s0.2,0.1,0.2,0.1l10.9,23.2l1,2.1h22.8C195.1,181.9,170.5,202.8,157.2,213.5z
			 M191.1,156.3l-10.7-22.9c-0.7-1.5-2.2-2.5-3.9-2.5c-1.6,0-3.2,0.9-4,2.4l-12.9,25.1l-10.4,20.3c0,0-0.1,0.2-0.2,0.1
			c-0.2,0-0.2-0.1-0.2-0.1l-8.6-20.3l-15.7-37.1c-0.7-1.7-2.4-2.7-4.2-2.7c-1.8,0-3.4,1.2-4.1,2.9l-13.1,34.9H81.5
			c-6.6-10.3-10.9-21-10.9-31.3c0-22.1,17.9-40,40-40c15.5,0,29.7,9.1,36.3,23.2l1.9,4.1l1.9-4.1C157.2,94,171.4,85,186.9,85
			c22.1,0,40,18,40,40c0,9.6-5.1,20.4-12.6,31.3H191.1z"/>
            </g>
    </svg>
);