import React from "react";

export const PainManagement = (props) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}>

        <g>
            <g>
                <g>
                    <path className="st0" d="M239.7,158.9c-0.7-1.3-4.7-7.5-17.7-6.1c-16.3,1.7-27.1-1-32.2-7.9c-4.5-6.2-5.2-15.5-5.7-21.7
				c-0.2-2.7-0.4-4.6-0.8-5.9c-0.7-2.1-2.8-5-5.7-7.8c7.6-0.9,14.4-4.6,19.3-10.5c4.9-6,7.2-13.6,6.4-21.3
				c-0.8-7.7-4.5-14.7-10.5-19.6c-6-4.9-13.6-7.2-21.3-6.4c-15.9,1.7-27.6,15.9-26,31.8c0.6,6.3,6.2,13.4,10,17.5
				c-11.4,0.1-36.9,1.4-52.1,10.7c-23.9,14.7-19.9,43.3-18.2,48c1.3,3.6,13.7,36.9,13.9,37.3c0.1,0.2,2.2,3.8,6.4,5.5
				c-0.2,5.7-0.1,10.4,0.3,12.5c1.5,7.7,9.8,10.4,22.8,12.2c2.8,0.4,5.5,0.6,8,0.6c7.2,0,13-1.7,17.4-5c5.4-4.1,6.8-9.5,7-10.7
				l11.6-44c4.4,4.6,12.6,11.7,21.3,12.9c3.1,0.4,30.3,4,41.7-5C243.5,169.8,241.7,162,239.7,158.9z M232.9,172.5
				c-8.1,6.4-27.9,5.6-38.4,4.1c-10.3-1.4-20.8-13.9-21.3-14.4l-2.7-3.3l-13.8,52.4c-0.1,0.6-2.6,15.1-27.4,11.5
				c-13.1-1.9-18.3-4.2-19.1-8.7c-0.3-1.5-0.5-6.3-0.1-13.1l0.1-1.8l-1.8-0.4c-2.6-0.7-4.4-2.6-5.2-3.7c0,0,0,0,0,0
				c-0.6-1.7-12-32.3-13.7-36.9c-1.5-4.1-5.1-29.6,16.3-42.7c18.3-11.2,54-10,54.2-10l2.3,0.5l0.7-3.7l-1.1-1
				c-2.4-2.1-11.3-11.5-12-18.2c-1.4-13.5,8.5-25.6,22-27c6.6-0.6,13,1.3,18.1,5.4c5.1,4.2,8.3,10.1,8.9,16.6
				c0.7,6.5-1.2,13-5.4,18.1c-4.2,5.1-10.1,8.3-16.6,8.9c-1.5,0.2-3,0.2-4.6,0c-1.7,0-2.3,0.9-2.5,1.6c-0.2,0.7-0.2,1.8,1.3,2.8
				c4.2,3.1,7.4,7.1,8.1,9c0.3,0.8,0.4,2.8,0.6,4.8l0,0.1c0.5,6.7,1.2,16.7,6.5,23.9c6,8.3,18.2,11.5,36.1,9.7
				c10.8-1.1,13.3,3.7,13.4,3.8l0.1,0.2C236.3,161.7,239.5,167.3,232.9,172.5z"/>
                </g>
            </g>
            <g>
                <path className="st0" d="M125.3,129.1c-5.8,1.7-9.7,3.6-11.8,5.9c-7.8,8.4-5.3,17.8-5.2,18.2l5.5,17.1l1.9-7.4
			c3-11.9,7.1-22.2,12.1-30.6l2.8-4.8L125.3,129.1z M112.6,151.9c-0.1-0.3-1.9-7.4,4.2-14c0.6-0.7,1.9-1.7,4.5-2.8
			c-3,5.8-5.6,12.4-7.8,19.6L112.6,151.9z"/>
            </g>
            <g>
                <g>
                    <path className="st0" d="M93.7,222.8c0.1,0.5,0,1-0.3,1.4l-10,11.7c-0.3,0.4-0.7,0.5-1.1,0.5c-0.3,0-0.7-0.1-0.9-0.3l-2.8-2.2
				l9.8-9.5c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.2-0.3-0.3-0.4l-3.9-1.7c-0.5-0.2-0.8-0.7-0.9-1.2c-0.1-0.5,0.1-1,0.5-1.4l14.3-11
				l1.7,1.2l-10.2,10.4c-0.1,0.1-0.2,0.3-0.1,0.5c0,0.2,0.2,0.3,0.3,0.4l2.8,1C93.2,221.9,93.6,222.3,93.7,222.8z"/>
                </g>
                <g>
                    <path className="st0" d="M77.1,194.5c-0.1,0.1-0.2,0.3-0.1,0.5l0.6,2.9c0.1,0.5,0,1-0.4,1.4c-0.3,0.3-0.7,0.5-1.1,0.5
				c-0.1,0-0.2,0-0.2,0l-15.2-2.3c-0.8-0.1-1.4-0.8-1.3-1.7l0.4-3.5l13.2,3.3c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.6-4.2
				c-0.1-0.5,0.1-1,0.5-1.4c0.4-0.3,0.9-0.5,1.5-0.3l16.9,6.3l-0.1,2l-14.2-3.1C77.4,194.3,77.3,194.3,77.1,194.5z"/>
                </g>
                <g>
                    <path className="st0" d="M78.6,213.1c0.4,0.4,0.6,1,0.5,1.5c-0.1,0.5-0.5,1-1,1.2l-16.6,7c-0.2,0.1-0.4,0.1-0.7,0.1
				c-0.6,0-1.2-0.4-1.5-1l-1.8-3.7l15.2-4.8c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0-0.5-0.1-0.7l-3.2-3.8c-0.4-0.5-0.5-1-0.3-1.6
				s0.6-1,1.2-1.1l20.8-4l1.1,2l-16,5.6c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2,0,0.5,0.2,0.6L78.6,213.1z"/>
                </g>
            </g>
        </g>
    </svg>
);