import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {List} from "../layout/List/List";
import Text from '../../assets/strings'
import {NavigationFooter} from "../layout/NavigationFooter/NavigationFooter";
import {useWindowWidth} from "../../hooks/windowWidth";
import useModal from "../layout/Modal/Modal";
import {useCookieConsents} from "@enzsft/react-cookie-consents";
import {fetchUserMeds, selectUserMeds} from "../../feature/userMeds/userMedicationSlice";
import {hashValidated, selectHash, selectLanguage, titleUpdated} from "../../feature/app/appSlice";
import {userMedsInstUpdated} from "../../feature/instructions/instructionsSlice";

export const MyMeds = () => {
    const hash = useSelector(state => selectHash(state));
    const lang = useSelector(state => selectLanguage(state));
    const showUserInst = useSelector( state => state.entities.instructions.showUserMedInstructions );
    const [formData, setFormData] = useState({});
    const [text, setText] = useState(Text[lang]);
    const {width} = useWindowWidth();
    const [Modal, open] = useModal('root');
    const cookieConsents = useCookieConsents();
    
    const dispatch = useDispatch();
    const data = useSelector( selectUserMeds );

    useEffect(() => {
        let str = Object.entries(formData).map(([key, val]) => {
            if (val != null && val !== "") {
                return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
            }
        }).join('&');
        
        str = str.length > 0 ? `?${str}` : `?lang=${lang}`;
    
        dispatch(fetchUserMeds({hash, str}));
    
        if(
            Object.keys(data).every((area) => data[area].length === 0) &&
            showUserInst === "0" &&
            cookieConsents.get().length > 0
        ){
            open();
        }
        
    }, [formData, hash, lang, cookieConsents.get()]);
    
    useEffect(() => {
        if(data.hasOwnProperty('hashIsValid') && !data.hashIsValid) {
            dispatch(hashValidated(data.hashIsValid))
        }
    }, [data]);

    useEffect(() => {
        setText(Text[lang]);
        dispatch(titleUpdated(text["menu"][0].name))
    }, [lang]);

    const handleFormChange = (e) => {
        e.preventDefault();

        const target = e.target.name;
        const value = e.target.value;

        setFormData({
            ...formData,
            [target]: value,
            lang: lang
        });

    };

    const handleSelectChange = (e) => {

        const data = {
            ...e,
            target: 'category'
        };

        setFormData({
            ...formData,
            [data.target]: data.value,
            lang: lang
        });

    };

    const handleClick = () => dispatch(userMedsInstUpdated());

    return (
        <>
            <Modal>
                <div className="welcome">
                    <p>
                        {text.web.welcome.text}
                    </p>
                    <ul>
                        <li>
                            {text.web.welcome["step-one"]}
                        </li>
                        <li>
                            {text.web.welcome["step-two"]}
                        </li>
                        <li>
                            {text.web.welcome["step-three"]}
                        </li>
                    </ul>
                    <div className="welcome-button-container">
                        <button onClick={handleClick}>
                            {text.web.welcome["button-message"]}
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="inner-content">
                <div className="medication__header">
                    <div className="inner-header">
                        <div className="title">
                            <h3>{text["menu"][0].name}</h3>
                        </div>
{/*                    <div className="add-med">
                        <Link to={`/${hash}/userMeds`}>New userMeds</Link>
                    </div>*/}
                    </div>
                </div>

                <form className="search-form"
                      onSubmit={(e) => e.preventDefault()}
                      onChange={(e) => handleFormChange(e)}>
                    <div className="field">
                        <input
                            className="input"
                            type="text"
                            autoComplete="off"
                            placeholder={text.web.name}
                            name="name"
                        />
                    </div>
                    <div className="field">
                        <Select
                            onChange={(e) => handleSelectChange(e)}
                            options={text["med-categories"]}
                            name="category"
                            placeholder={text.web.category}
                            id="med-category"
                        />
                    </div>
                </form>

                <List items={data} hash={hash}/>
    
                {
                    width <= 768
                        ? <NavigationFooter/>
                        : null
                }
            </div>

        </>

    );
};