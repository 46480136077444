import React, {useEffect} from 'react';
import termsData from "../../assets/terms";
import Text from "../../assets/strings";
import "./Terms.scss";
import {useDispatch, useSelector} from "react-redux";
import {selectLanguage, titleUpdated} from "../../feature/app/appSlice";

export const Terms = (props) =>{

    const lang = useSelector(state => selectLanguage(state));
    const dispatch = useDispatch();
    
    useEffect(()=> {
        dispatch(titleUpdated(Text[lang].web.terms.title));
    },[lang]);
    
    
    return(
        <div className="inner-content">
            <div className="medication__header">
                <div className="inner-header">
                    <div className="title">
                        <h3>{Text[lang].web.terms.title}</h3>
                    </div>
                </div>
            </div>

            <div className="terms-container" dangerouslySetInnerHTML={{__html: termsData[lang]}}/>
        </div>
    )
};

