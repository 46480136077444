import React, { useEffect } from "react";
import { Header } from "./components/layout/Header";
import { Content } from "./components/layout/Content";
import { toast } from "react-toastify";
import config from "./config/business/config";
import { CookieConsentsProvider } from "@enzsft/react-cookie-consents";
import { useSelector } from "react-redux";
import store from "./context/store";
import {
  hashUpdated,
  languageUpdated,
  selectCookieType,
  selectTheme,
  themeUpdated,
} from "./feature/app/appSlice";
import { useMatomo } from "@datapunt/matomo-tracker-react";

export const App = (props) => {
  const hash = props.match.params.hash;

  const { trackPageView } = useMatomo();

  if (store.getState().entities.app.hash === "") {
    store.dispatch(hashUpdated(hash));
  }

  const cookies = useSelector((state) => selectCookieType(state));

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    searchParams.forEach(function (value, key) {
      if (
        key === "id" &&
        config.companies.findIndex((obj) => obj.value === value)
      ) {
        const index = config.companies.findIndex((obj) => obj.value === value);

        store.dispatch(themeUpdated(config.companies[index].title));
      }
      if (key === "lang" && config["languages"].includes(value)) {
        store.dispatch(languageUpdated(value));
      }
    });
  }, []);

  toast.configure({
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    newestOnTop: true,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    draggable: true,
    pauseOnHover: true,
  });

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && cookies === "all") {
      trackPageView();
    }
  }, [cookies, trackPageView]);

  const theme = useSelector((state) => selectTheme(state));

  useEffect(() => {
    let manifestFile = document.getElementById("manifest")
    let favicon = document.getElementById("favicon")

    if (theme === "invenimus") {
      document.title = "Invenimus";
      manifestFile.href = "/manifest_invenimus.json"
      favicon.href = "/invenimus_icons/favicon-32x32-seochecker-manifest-7510.png"
    } else {
      manifestFile.href = "/manifest.json"
      favicon.href = "/favicon.ico"
    }
  }, [theme]);

  return (
    <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
      <Header />
      <Content />
    </CookieConsentsProvider>
  );
};
