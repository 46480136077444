import React from "react";

export const Danger = (props) => (
    <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}
         style={{color:'#dc3545'}}>
        <g>
            <g>
                <g>
                    <path className="st0" d="M281.4,222.1L187.2,33.4C179.9,18.9,165,9.7,148.8,9.7s-31.1,9.2-38.3,23.8L16.2,222.1
				c-6.7,13.3-6,29,1.9,41.7c7.8,12.7,21.5,20.3,36.3,20.3 M144.4,102.9c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8v34.9
				c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8V102.9z M96.1,115c1.1-1.1,2.9-1.1,4,0l24.7,24.7c1.1,1.1,1.1,2.9,0,4
				c-0.6,0.6-1.3,0.8-2,0.8c-0.7,0-1.4-0.3-2-0.8l-24.7-24.7C95,117.8,95,116.1,96.1,115z M195.3,232.4c-1.2,3.8-4.8,6.4-9,6.5
				c-2.2,0-4.4,0-6.5,0c-1,0-1.9,0-2.9,0h-18.6c-16.5,0-33,0-49.5,0c-4.9,0-8.5-2.5-9.8-6.9c-0.8-2.7-0.4-5.7,1.3-8.1
				c1.6-2.4,4.2-3.9,7.1-4.1c0.2,0,0.5,0,0.8-0.1l2.9-0.4l0-13c0-6.1,0-12.1,0-18.2c0-9.8,3.3-18.2,9.8-25.1
				c7.7-8.1,17.3-11.9,28.6-11.3c14.7,0.8,27.6,10.8,32.2,25c1.2,3.7,1.8,7.5,1.7,11.7c0,6.2,0,12.5,0,18.7l0,12.5l2.7,0.1
				c2.4,0.1,4.2,0.7,5.8,1.9C195.3,224.2,196.6,228.3,195.3,232.4z M199.1,119.9l-24.7,24.7c-0.6,0.6-1.3,0.8-2,0.8
				c-0.7,0-1.4-0.3-2-0.8c-1.1-1.1-1.1-2.9,0-4l24.7-24.7c1.1-1.1,2.9-1.1,4,0C200.2,117,200.2,118.8,199.1,119.9z"/>
                    <path className="st0" d="M281.4,222.1L187.2,33.4C179.9,18.9,165,9.7,148.8,9.7s-31.1,9.2-38.3,23.8L16.2,222.1
				c-6.7,13.3-6,29,1.9,41.7c7.8,12.7,21.5,20.3,36.3,20.3h188.8c14.7,0,28.5-7.6,36.3-20.3C287.4,251.1,288.1,235.4,281.4,222.1z
				 M144.4,102.9c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8v34.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8V102.9z M96.1,115
				c1.1-1.1,2.9-1.1,4,0l24.7,24.7c1.1,1.1,1.1,2.9,0,4c-0.6,0.6-1.3,0.8-2,0.8c-0.7,0-1.4-0.3-2-0.8l-24.7-24.7
				C95,117.8,95,116.1,96.1,115z M195.3,232.4c-1.2,3.8-4.8,6.4-9,6.5c-2.2,0-4.4,0-6.5,0c-1,0-1.9,0-2.9,0h-18.6
				c-16.5,0-33,0-49.5,0c-4.9,0-8.5-2.5-9.8-6.9c-0.8-2.7-0.4-5.7,1.3-8.1c1.6-2.4,4.2-3.9,7.1-4.1c0.2,0,0.5,0,0.8-0.1l2.9-0.4
				l0-13c0-6.1,0-12.1,0-18.2c0-9.8,3.3-18.2,9.8-25.1c7.7-8.1,17.3-11.9,28.6-11.3c14.7,0.8,27.6,10.8,32.2,25
				c1.2,3.7,1.8,7.5,1.7,11.7c0,6.2,0,12.5,0,18.7l0,12.5l2.7,0.1c2.4,0.1,4.2,0.7,5.8,1.9C195.3,224.2,196.6,228.3,195.3,232.4z
				 M199.1,119.9l-24.7,24.7c-0.6,0.6-1.3,0.8-2,0.8c-0.7,0-1.4-0.3-2-0.8c-1.1-1.1-1.1-2.9,0-4l24.7-24.7c1.1-1.1,2.9-1.1,4,0
				C200.2,117,200.2,118.8,199.1,119.9z"/>
                    <path className="st0" d="M130,164.1c-7.5,7.9-11.4,17.7-11.4,28.9c0,1.6,1.3,2.8,2.8,2.8c0,0,0,0,0,0c1.6,0,2.8-1.3,2.8-2.8
				c0-4.6,0.8-8.8,2.2-12.8l0.7-1.8c1.6-3.8,3.9-7.4,7-10.6c1.1-1.1,1-2.9-0.1-4C132.9,162.9,131.1,162.9,130,164.1z"/>
                </g>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
);