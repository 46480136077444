import React from "react";
import Text from "../../../assets/strings";
import {GlobalScoreIcon} from "./GlobalScoreIcon";

export const GlobalScore = (props) => (
    <div className="medication-score">
        <h5>{Text[props.lang].web.medication['global-score']}</h5>
        <GlobalScoreIcon level={props.level} size={90}/>
    </div>
);
