import React from "react";
import {useStore} from "../../../context";
import Text from "../../../assets/strings";
import {Link} from "react-router-dom";
import {Favorite} from "../icons/Favorite";
import {Cardiovascular} from "../icons/Cardiovascular";
import {Diabetes} from "../icons/Diabetes/Diabetes";
import {Oncology} from "../icons/Oncology";
import {PainManagement} from "../icons/PainManagement";
import {Psychiatry} from "../icons/Psychiatry/Psychiatry";
import {useDispatch, useSelector} from "react-redux";
import {selectHash, selectLanguage} from "../../../feature/app/appSlice";
import {stateRemoved} from "../../../feature/meds/medsSlice";


export const Dashboard = () => {
    const hash = useSelector(state => selectHash(state));
    const lang = useSelector(state => selectLanguage(state));
    const menu = Text[lang].menu;
    const dispatch = useDispatch()

    return (
        <div className="inner-content">
            <div className="menu-container">
                <Link className="item" to={`/${hash}/${menu[0].id}`} >
                    <div className="name">
                        {menu[0].name}
                    </div>
                    <div className="icon-container">
                        <Favorite size={75} sidebar={true}/>
                    </div>
                </Link>

                <Link className="item" to={`/${hash}/${menu[1].id}`} onClick={() => dispatch(stateRemoved())}>
                    <div className="name">
                        {menu[1].name}
                    </div>
                    <div className="icon-container">
                        <Psychiatry size={110}/>
                    </div>
                </Link>

                <Link className="item" to={`/${hash}/${menu[2].id}`} onClick={() => dispatch(stateRemoved())}>
                    <div className="name">
                        {menu[2].name}
                    </div>
                    <div className="icon-container">
                        <PainManagement size={110}/>
                    </div>
                </Link>

                <Link className="item" to={`/${hash}/${menu[3].id}`} onClick={() => dispatch(stateRemoved())}>
                    <div className="name">
                        {menu[3].name}
                    </div>
                    <div className="icon-container">
                        <Oncology size={110}/>
                    </div>
                </Link>

                <Link className="item" to={`/${hash}/${menu[4].id}`} onClick={() => dispatch(stateRemoved())}>
                    <div className="name">
                        {menu[4].name}
                    </div>
                    <div className="icon-container">
                        <Diabetes size={110}/>
                    </div>
                </Link>

                <Link className="item" to={`/${hash}/${menu[5].id}`} onClick={() => dispatch(stateRemoved())}>
                    <div className="name">
                        {menu[5].name}
                    </div>
                    <div className="icon-container">
                        <Cardiovascular size={110}/>
                    </div>
                </Link>
           </div>
        </div>
    )
};