import React from "react";
import {Level1a} from "./icons/levels/Level1a";
import {Level1b} from "./icons/levels/Level1b";
import {Level2a} from "./icons/levels/Level2a";
import {Level2b} from "./icons/levels/Level2b";


export const EvidenceLevel = (props) => {
    switch(props.level) {
        case "1A":
            return <Level1a size={props.size}/>;
        case "1B":
            return <Level1b size={props.size}/>;
        case "2A":
            return <Level2a size={props.size}/>;
        case "2B":
            return <Level2b size={props.size}/>;
        default:
            return null;
    }
};