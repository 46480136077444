import React from "react";

export const Level2b = (props) => (
    <svg version="1.1" id="Layer_1"
         height={`${props.size}px`}
         width={`${props.size}px`}
         focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve"
         style={{position: 'relative', bottom:'2px'}}>

        <g>
            <path className="st0" d="M6.4,12.3c0-0.2,0-0.4,0.1-0.6s0.1-0.4,0.2-0.5C6.7,11,6.9,10.8,7,10.7s0.4-0.4,0.7-0.6C7.9,10,8,9.9,8.1,9.8
		c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.2S8.3,8.5,8.2,8.4
		c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0.1-0.5,0.2C7.3,8.7,7.3,8.9,7.3,9.2H6.4c0-0.5,0.1-0.9,0.4-1.1c0.3-0.3,0.6-0.4,1.1-0.4
		c0.2,0,0.4,0,0.6,0.1C8.7,7.8,8.9,7.9,9,8c0.1,0.1,0.2,0.2,0.3,0.4C9.4,8.6,9.4,8.8,9.4,9c0,0.2,0,0.3-0.1,0.5
		c0,0.1-0.1,0.3-0.2,0.4C9,10,8.9,10.1,8.7,10.3c-0.2,0.1-0.4,0.3-0.6,0.5C8,10.9,7.8,11,7.7,11.2c-0.1,0.1-0.2,0.3-0.2,0.4h1.9v0.8
		H6.4z M10,12.3V7.8h2.2c0.4,0,0.8,0.1,1,0.3c0.2,0.2,0.3,0.5,0.3,0.9c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.2,0.3-0.4,0.3
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.4-0.1,0.7-0.4,1
		c-0.3,0.2-0.7,0.4-1.3,0.4H10z M10.9,8.6v1H12c0.4,0,0.6-0.2,0.6-0.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.5-0.1H10.9z
		 M12.1,11.6c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.2v1.2H12.1z
		 M10,1.4c-4.8,0-8.7,3.9-8.7,8.6c0,1.6,0.4,3,1.1,4.3c0.8,1.4,1.9,2.5,3.3,3.3c1.3,0.7,2.7,1.1,4.3,1.1c1.5,0,2.9-0.4,4.2-1.1
		c1.4-0.8,2.5-1.9,3.3-3.2c0.7-1.3,1.2-2.8,1.2-4.3C18.7,5.2,14.8,1.4,10,1.4z"/>
        </g>
    </svg>
);