import React, {useEffect} from 'react';
import {useStore} from "../../context";
import {Graph} from "./Graph";
import "./Help.scss";
import {Psychiatry} from "../layout/icons/Psychiatry/Psychiatry";
import {PainManagement} from "../layout/icons/PainManagement";
import {Oncology} from "../layout/icons/Oncology";
import {Diabetes} from "../layout/icons/Diabetes/Diabetes";
import {Cardiovascular} from "../layout/icons/Cardiovascular";
import {Danger} from "../layout/icons/Danger";
import {Warning} from "../layout/icons/Warning";
import {Success} from "../layout/icons/Success";
import {MetaAltered} from "../layout/icons/MetaAltered";
import {LowEffect} from "../layout/icons/LowEffect";
import {SideEffectRisk} from "../layout/icons/SideEffectRisk/SideEffectRisk";
import {Level1a} from "../layout/icons/levels/Level1a";
import {DrugLabels} from "../layout/icons/DrugLabels";
import {GuideLines} from "../layout/icons/GuideLines";
import Text from "../../assets/help";
import ReactGA from "react-ga";
import {Instructions} from "./Instructions";
import {useDispatch, useSelector} from "react-redux";
import {selectCookieType, selectLanguage, titleUpdated} from "../../feature/app/appSlice";


export const Help = () => {
    const lang = useSelector(state => selectLanguage(state));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(titleUpdated(Text[lang].title));
    }, [lang]);

    return (
        <div className="inner-content">
            <div className="medication__header">
                <div className="inner-header">
                    <div className="title">
                        <h3>{Text[lang].title}</h3>
                    </div>
                </div>
            </div>

            <div className="help-container">
                <p>{Text[lang].p1}</p>

                <ul className="functionalities">
                    <li>{Text[lang].functionalities["1"]}</li>
                    <li>{Text[lang].functionalities["2"]}</li>
                    <li>{Text[lang].functionalities["3"]}</li>
                    <li>{Text[lang].functionalities["4"]}</li>
                </ul>

                <p>
                    {Text[lang].p2}
                </p>

                <ul className="areas">
                    <li>
                        <Psychiatry size={80}/>
                        <span>
                            {Text[lang].areas["1"]}
                        </span>
                    </li>
                    <li>
                        <PainManagement size={80}/>
                        <span>
                            {Text[lang].areas["2"]}
                        </span>
                    </li>
                    <li>
                        <Oncology size={80}/>
                        <span>
                            {Text[lang].areas["3"]}
                        </span>
                    </li>
                    <li>
                        <Diabetes size={80}/>
                        <span>
                            {Text[lang].areas["4"]}
                        </span>
                    </li>
                    <li>
                        <Cardiovascular size={80}/>
                        <span>
                            {Text[lang].areas["5"]}
                        </span>
                    </li>
                </ul>

                <p>
                    {Text[lang].p3}
                </p>
                <p>
                    {Text[lang].p4}
                </p>
                <p>
                    {Text[lang].p5}
                </p>


                <ul className="drug-states">
                    <li>
                        <Danger size={100}/>
                        <p>
                            {Text[lang]["drug-states"]["1"]}
                        </p>
                    </li>
                    <li>
                        <Warning size={100}/>
                        <p>
                            {Text[lang]["drug-states"]["2"]}
                        </p>
                    </li>
                    <li>
                        <Success size={100}/>
                        <p>
                            {Text[lang]["drug-states"]["3"]}
                        </p>
                    </li>
                </ul>

                <p>
                    {Text[lang].p6}
                </p>
                <p>
                    {Text[lang].p7}
                </p>

                <ul className="gene-symbols">
                    <li>
                        <MetaAltered size={80}/>
                        <span>
                            {Text[lang]["gene-symbols"]["1"]}
                        </span>
                    </li>
                    <li>
                        <LowEffect size={80}/>
                        <span>
                            {Text[lang]["gene-symbols"]["2"]}
                        </span>
                    </li>
                    <li>
                        <SideEffectRisk size={80}/>
                        <span>
                            {Text[lang]["gene-symbols"]["3"]}
                        </span>
                    </li>
                </ul>


                <p>
                    <b>
                        {Text[lang].p8}
                    </b>
                </p>

                <p>
                    {Text[lang].p9}
                </p>
                <p>
                    {Text[lang].p10}
                </p>

                <ul className="drug-states">
                    <li>
                        <Level1a size={100}/>
                        <div>
                            <p>
                                {Text[lang]["gene-states"]["1"].p}
                            </p>
                            <sub>
                                {Text[lang]["gene-states"]["1"].sub}
                            </sub>
                        </div>
                    </li>
                    <li>
                        <GuideLines size={100}/>
                        <p>
                            {Text[lang]["gene-states"]["2"]}
                        </p>
                    </li>
                    <li>
                        <DrugLabels size={100}/>
                        <p>
                            {Text[lang]["gene-states"]["3"]}
                        </p>
                    </li>
                </ul>

                <p>
                    {Text[lang].p11}
                </p>
                <p>
                    {Text[lang].p12}
                </p>

                <Graph lang={lang}/>

                <sub>
                    <b>{Text[lang].sub.b}</b>
                    {Text[lang].sub.p}
                </sub>
                <div className="levels">
                    <h5>{Text[lang].levels.title1}</h5>
                    <p>
                        {Text[lang].levels.p1}
                    </p>

                    <h5>{Text[lang].levels.title2}</h5>
                    <p dangerouslySetInnerHTML={{__html: Text[lang].levels.p2}} />

                    <h5>{Text[lang].levels.title3}</h5>
                    <p>
                        {Text[lang].levels.p3}
                    </p>

                    <h5>{Text[lang].levels.title4}</h5>
                    <p dangerouslySetInnerHTML={{__html: Text[lang].levels.p4}}/>


                    <h5>{Text[lang].levels.title5}</h5>
                    <p>
                        {Text[lang].levels.p5}
                    </p>

                    <h5>{Text[lang].levels.title6}</h5>
                    <p dangerouslySetInnerHTML={{__html: Text[lang].levels.p6}}/>
                </div>
            </div>
        </div>
    );
};
