import React from "react";

export const DrugLabels = (props) => (
    <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}
         style={{paddingBottom: '1px'}}>
        <g>
            <path className="st0" d="M10.9,11.1V8.3c0-0.5,0.4-0.9,0.8-0.9V5.9c0,0,0,0,0,0v0c0,0,0,0,0,0l-1.5,0c-0.2,0-0.3-0.1-0.3-0.3l0-1.7
		c0,0,0,0,0,0h-4c-0.2,0-0.3,0.2-0.3,0.3v8.7c0,0.2,0.2,0.3,0.3,0.3h5.5c0.2,0,0.3-0.2,0.3-0.3V12C11.3,11.9,10.9,11.5,10.9,11.1z
		 M10.2,10.9H7.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1h3.2c0.1,0,0.1,0.1,0.1,0.1C10.4,10.8,10.3,10.9,10.2,10.9z
		 M10.2,9.9H7.1C7,9.9,6.9,9.9,6.9,9.8S7,9.6,7.1,9.6h3.2c0.1,0,0.1,0.1,0.1,0.1S10.3,9.9,10.2,9.9z M10.2,9H7.1
		C7,9,6.9,8.9,6.9,8.8S7,8.7,7.1,8.7h3.2c0.1,0,0.1,0.1,0.1,0.1S10.3,9,10.2,9z M10.2,8H7.1C7,8,6.9,7.9,6.9,7.8
		c0-0.1,0.1-0.1,0.1-0.1h3.2c0.1,0,0.1,0.1,0.1,0.1C10.4,7.9,10.3,8,10.2,8z M10.2,7H7.1C7,7,6.9,7,6.9,6.9c0-0.1,0.1-0.1,0.1-0.1
		h3.2c0.1,0,0.1,0.1,0.1,0.1C10.4,7,10.3,7,10.2,7z"/>
            <path className="st1" d="M11.2,8.3v1.4h0.6V7.6C11.4,7.7,11.2,7.9,11.2,8.3z"/>
            <polygon className="st0" points="11.5,5.6 10.2,4.1 10.2,5.6 	"/>
            <path className="st0" d="M8.9,0C4,0,0,4,0.1,8.8c0,1.6,0.4,3,1.2,4.3c0.8,1.4,2,2.5,3.4,3.3c1.3,0.7,2.8,1.1,4.3,1.1
		c1.5,0,3-0.4,4.2-1.1c1.4-0.8,2.6-1.9,3.4-3.3c0.8-1.3,1.2-2.8,1.2-4.4C17.6,3.9,13.7,0,8.9,0z M12.8,11.1c0,0.4-0.3,0.8-0.7,0.9
		v0.9c0,0.4-0.3,0.6-0.6,0.6H5.9c-0.4,0-0.6-0.3-0.6-0.6V4.1c0-0.4,0.3-0.6,0.6-0.6h4c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0.1,0l1.9,2.1
		l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C12,5.8,12,5.8,12,5.9v1.5c0.4,0.1,0.7,0.5,0.7,0.9V11.1z"/>
            <path className="st1" d="M12.6,9.7V8.3c0-0.3-0.2-0.6-0.5-0.7v2H12.6z"/>
        </g>
        <path className="st0" d="M11.2,9.7V8.3c0-0.4,0.3-0.7,0.7-0.7h0c0.4,0,0.7,0.3,0.7,0.7v1.3"/>
    </svg>

);