import React from "react";

export const Loading = (props) => (
    // width="200px" height="100px"
    <svg className="loading"  xmlns="http://www.w3.org/2000/svg" viewBox="0 25 100 50"
        preserveAspectRatio="xMidYMid"
        height={`${props.height}px`}
        width={`${props.width}px`}
         fill={'#FFF'}
    >
        <circle cx="6.451612903225806" cy="38.8371" r="3.38943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-0.5s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="0s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-0.5s"/>
        </circle>
        <circle cx="6.451612903225806" cy="61.163" r="2.61057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-1.5s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-0.5s"/>
        </circle>
        <circle cx="16.129032258064512" cy="51.761" r="3.57057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-0.7s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-0.2s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-0.7s"/>
        </circle>
        <circle cx="16.129032258064512" cy="48.239" r="2.42943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-1.7s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1.2s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-0.7s"/>
        </circle>
        <circle cx="25.806451612903224" cy="63.3242" r="3.33057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-0.9s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-0.4s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-0.9s"/>
        </circle>
        <circle cx="25.806451612903224" cy="36.6758" r="2.66943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-1.9s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1.4s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-0.9s"/>
        </circle>
        <circle cx="35.48387096774193" cy="67.7125" r="3.09057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-1.1s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-0.6s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.1s"/>
        </circle>
        <circle cx="35.48387096774193" cy="32.2875" r="2.90943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-2.1s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1.6s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.1s"/>
        </circle>
        <circle cx="45.16129032258064" cy="67.1843" r="2.85057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-1.3s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-0.8s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.3s"/>
        </circle>
        <circle cx="45.16129032258064" cy="32.8157" r="3.14943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-2.3s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1.8s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.3s"/>
        </circle>
        <circle cx="54.838709677419345" cy="61.163" r="2.61057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-1.5s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.5s"/>
        </circle>
        <circle cx="54.838709677419345" cy="38.8371" r="3.38943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-2.5s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-2s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.5s"/>
        </circle>
        <circle cx="64.51612903225805" cy="48.239" r="2.42943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-1.7s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1.2s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.7s"/>
        </circle>
        <circle cx="64.51612903225805" cy="51.761" r="3.57057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-2.7s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-2.2s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.7s"/>
        </circle>
        <circle cx="74.19354838709677" cy="36.6758" r="2.66943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-1.9s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1.4s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.9s"/>
        </circle>
        <circle cx="74.19354838709677" cy="63.3242" r="3.33057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-2.9s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-2.4s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-1.9s"/>
        </circle>
        <circle cx="83.87096774193547" cy="32.2875" r="2.90943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-2.1s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1.6s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-2.1s"/>
        </circle>
        <circle cx="83.87096774193547" cy="67.7125" r="3.09057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-3.1s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-2.6s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-2.1s"/>
        </circle>
        <circle cx="93.54838709677418" cy="32.8157" r="3.14943">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-2.3s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-1.8s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #61dafb; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-2.3s"/>
        </circle>
        <circle cx="93.54838709677418" cy="67.1843" r="2.85057">
            <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
                     dur="2s" repeatCount="indefinite" begin="-3.3s"/>
            <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite"
                     begin="-2.8s"
                     keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"/>
            <animate attributeName="fill" keyTimes="0;0.5;1" values="#2761a1; #4eb3e7; #2761a1" dur="2s"
                     repeatCount="indefinite" begin="-2.3s"/>
        </circle>
    </svg>
);