import React from "react";
import {Link} from "react-router-dom";
import {Favorite} from "../icons/Favorite";
import {SideEffects} from "../icons/SideEffects";
import {titleUpdated} from "../../../feature/app/appSlice";
import {useDispatch} from "react-redux";
import {medicationStateReset} from "../../../feature/medication/medicationSlice";

export const ListItem = (props) => {
    const dispatch = useDispatch();
    return (
        <li key={props.drug.drug_id} className="healthy-med">
            <Link to={{
                pathname: `/${props.hash}/medication/${props.drug.drug_id}`,
                state: {...props.drug}
            }}
                  onClick={() => dispatch(medicationStateReset())}
            >
                <span>{props.drug.name}</span>
                {props.drug.isFavorite ? <span><Favorite size={15}/></span> : null}
                {props.drug.hasEffect ? <span><SideEffects size={15}/></span> : null}
            </Link>
        </li>
    );
};
