import {ToggleButton} from "../ToggleButton";
import React from "react";
import Text from "../../../assets/strings";
import {GlobalScoreIcon} from "../GlobalScore/GlobalScoreIcon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {AiOutlineInfoCircle} from "react-icons/ai";

export const GlobalScoreFooter = (props) => (
    <div className="medication-score-footer">
        <div className="inner">
            <div className="global-score">
                <span>{Text[props.lang].web.medication['global-score']}</span>
                <GlobalScoreIcon level={props.level} size={40}/>
            </div>
            <div className="toggle-button-container">
                <ToggleButton state={props.isFavorite} click={() => props.click(props.isFavorite)}/>
                <div className="info-container">
                    <OverlayTrigger
                        key='top'
                        placement='top'
                        overlay={
                            <Tooltip id="tooltip-top">
                                {Text[props.lang].web.medication['drug-info-icon']}
                            </Tooltip>
                        }
                    >
                        <AiOutlineInfoCircle size={20}/>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    </div>
);