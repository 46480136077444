import React, {useEffect, useState} from 'react'
import {List} from "../layout/List/List";
import Text from "../../assets/strings";
import {NavigationFooter} from "../layout/NavigationFooter/NavigationFooter";
import {useWindowWidth} from "../../hooks/windowWidth";
import Modal from "react-bootstrap/Modal";
import useModal from "../layout/Modal/Modal";
import {useCookieConsents} from "@enzsft/react-cookie-consents";
import {useDispatch, useSelector} from "react-redux";
import {hashValidated, selectCookieType, selectHash, selectLanguage, titleUpdated} from "../../feature/app/appSlice";
import {fetchMeds, selectMedsForCategory} from "../../feature/meds/medsSlice";
import {categoryInstUpdated} from "../../feature/instructions/instructionsSlice";

export const MedCategory = (props) => {
    const hash = useSelector(state => selectHash(state));
    const lang = useSelector(state => selectLanguage(state));
    const showCategoryInst = useSelector(state => state.entities.instructions.showCategoryInstructions);
    const categoryId = props.match.params.category;
    const data = useSelector(state => selectMedsForCategory(categoryId)(state));
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [text, setText] = useState(Text[lang]);
    const category = text["med-categories"].find(x => x.value === categoryId);
    const {width} = useWindowWidth();
    const [Modal, open] = useModal('root');
    const cookieConsents = useCookieConsents();
    
    useEffect(() => {
        
        const str = `?lang=${lang}`;
        
        if (hash && cookieConsents.get().length > 0) {
            dispatch(
                fetchMeds({hash, categoryId, str})
            );
        }
        
        if (showCategoryInst === "0" && cookieConsents.get().length > 0) {
            open();
        }
        
        
    }, [categoryId, hash, lang, cookieConsents.get()]);
    
    useEffect(() => {
        
        let str = Object.entries(formData).map(([key, val]) => {
            if (val != null && val !== "") {
                return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
            }
        }).join('&');
        
        str = str.length > 0 ? `?${str}` : `?lang=${lang}`;
        
        if (Object.keys(formData).length !== 0) {
            
            dispatch(
                fetchMeds({hash, categoryId, str})
            );
        }
        
    }, [formData, lang]);
    
    
    useEffect(() => {
        if (data.hasOwnProperty('hashIsValid') && !data.hashIsValid) {
            dispatch(hashValidated(data.hashIsValid))
        }
    }, [data]);
    
    useEffect(() => {
        setFormData({});
        document.getElementById("search").reset();
    }, [categoryId]);
    
    useEffect(() => {
        setText(Text[lang]);
        dispatch(titleUpdated(category.label))
    }, [lang]);
    
    const handleFormChange = (e) => {
        e.preventDefault();
        const target = e.target.name;
        const value = e.target.value;
        
        setFormData({
            ...formData,
            [target]: value,
            lang: lang
        });
        
    };
    
    return (
        <>
            <Modal>
                <div className="welcome">
                    <p>
                        {text.web.welcome['drug-text']}
                    </p>
                    <div className="welcome-button-container">
                        <button onClick={() => dispatch(categoryInstUpdated())}>
                            {text.web.welcome["button-message"]}
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="inner-content">
                <div className="medication__header">
                    <div className="inner-header">
                        <div className="title">
                            <h3>{category.label}</h3>
                        </div>
                    </div>
                </div>
                
                <form className="search-form" id="search" onSubmit={(e) => e.preventDefault()}
                      onChange={(e) => handleFormChange(e)}>
                    <div className="field">
                        <input className="input" type="text" autoComplete="off" placeholder={text.web.search}
                               name="name"/>
                    </div>
                </form>
                
                <List items={data} hash={hash}/>
                {
                    width <= 768
                        ? <NavigationFooter/>
                        : null
                }
            </div>
        </>
    )
};

