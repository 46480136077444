import React from "react";

export const Oncology = (props) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 297.6 293.7" xmlSpace="preserve"
         height={`${props.size}px`}
         width={`${props.size}px`}>

        <g>
            <g>
                <path className="st1" d="M9.5,145.6c0.1-18.7,4-36.6,10.9-52.8C13.4,109,9.5,126.9,9.5,145.6z"/>
            </g>
        </g>
        <g>
            <g>
                <g>
                    <g>
                        <path className="st0" d="M147,203.8C146.9,203.8,146.9,203.8,147,203.8c-0.6-0.6-1.2-1.2-1.8-1.8c-1.9,1.9-3.8,3.8-5.7,5.6
					c-4.4,4.2-15.3,14.6-21.3,19.6c-2,1.7-4.7,2.4-7.3,1.9c-2.6-0.5-4.8-2.1-6.1-4.5l-5.9-11c-2.1-3.9-1-8.7,2.6-11.3
					c2.3-1.6,6.5-5.1,10.9-8.8c3.1-2.6,6.1-5.1,8.4-6.9c2.1-1.6,4.2-3.4,6.5-5.3c-0.5-0.7-1-1.3-1.5-2c0,0-0.1-0.1-0.1-0.1
					c-0.5-0.6-1-1.3-1.4-1.9c-2.3,1.9-4.4,3.7-6.5,5.3c-2.3,1.8-5.4,4.4-8.5,7c-4.2,3.5-8.5,7.1-10.6,8.6c-5.6,4-7.4,11.6-4.1,17.7
					l5.9,11c2,3.7,5.5,6.2,9.6,7c0.9,0.2,1.7,0.3,2.6,0.3c3.2,0,6.4-1.1,8.9-3.3c6.1-5.1,17-15.5,21.5-19.8c1.9-1.8,3.8-3.7,5.8-5.6
					C148.1,205,147.5,204.4,147,203.8z M128.1,174.1L128.1,174.1c0.5,0.7,1,1.3,1.5,2L128.1,174.1z"/>
                    </g>
                    <g>
                        <path className="st0" d="M149.7,58.3L149.7,58.3l0.5,0C150.1,58.3,149.9,58.3,149.7,58.3z M145.2,158.1L145.2,158.1l1.7,1.8
					C146.3,159.3,145.8,158.7,145.2,158.1z M169.8,181.2l0.3,0.3v0C170,181.4,169.9,181.3,169.8,181.2z"/>
                    </g>
                    <g>
                        <path className="st0" d="M168.1,179.7c-0.6-0.5-1.1-0.9-1.6-1.4C167,178.8,167.5,179.3,168.1,179.7c0.3,0.2,0.6,0.5,0.8,0.7
					L168.1,179.7z M168.1,179.7c-0.6-0.5-1.1-0.9-1.6-1.4C167,178.8,167.5,179.3,168.1,179.7c0.3,0.2,0.6,0.5,0.8,0.7L168.1,179.7z
					 M169.3,174.1L169.3,174.1L169.3,174.1C169.3,174.2,169.3,174.1,169.3,174.1z M145.6,58.3c0.7,0,1.4,0,2.1,0v0
					C147,58.3,146.3,58.3,145.6,58.3z M137.4,58.9c1.3-0.1,2.5-0.3,3.8-0.4C139.9,58.6,138.6,58.8,137.4,58.9z M121.2,62.8
					c2.2-0.8,4.4-1.5,6.8-2.2C125.5,61.3,123.3,62,121.2,62.8z M103.9,74.3c0.4-0.4,0.8-0.9,1.2-1.3
					C104.7,73.5,104.3,73.9,103.9,74.3z"/>
                        <path className="st0" d="M198.9,198.5c-5.2-3.7-17.4-14-25.7-21c17.4-23.5,23.4-41.8,27.9-55.4l0.8-2.4c3.8-11.3,4.4-28.2-5.3-41.7
					c-2.1-2.9-4.9-6-8.8-8.9c-0.3-0.3-0.7-0.5-1-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5-0.4-1.1-0.7-1.6-1.1c-0.4-0.3-0.9-0.6-1.4-0.8
					c-0.2-0.1-0.4-0.2-0.6-0.3c-0.9-0.5-1.9-1-2.9-1.5c-0.5-0.2-1-0.5-1.5-0.7c-0.5-0.2-1-0.4-1.6-0.7c-0.3-0.1-0.5-0.2-0.8-0.3
					c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4-0.1-0.8-0.3-1.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.2c-0.7-0.2-1.4-0.4-2.1-0.6c-1-0.3-2-0.6-3.1-0.8
					c-0.9-0.2-1.8-0.4-2.7-0.6c-0.3-0.1-0.6-0.1-0.9-0.2c-0.5-0.1-1.1-0.2-1.6-0.3c-0.5-0.1-1.1-0.2-1.6-0.3
					c-0.7-0.1-1.5-0.2-2.2-0.3c-0.4,0-0.8-0.1-1.1-0.1c-0.4,0-0.8-0.1-1.1-0.1c-0.3,0-0.6,0-0.9-0.1c-0.4,0-0.9-0.1-1.3-0.1
					c-0.1,0-0.2,0-0.3,0c-0.9,0-1.8-0.1-2.8-0.1c-0.5,0-1,0-1.5,0h-0.5c-0.3,0-0.7,0-1,0l-0.9,0h-0.1c-0.7,0-1.4,0-2.1,0
					c-1.3,0-2.5,0.1-3.7,0.2c-0.2,0-0.5,0-0.7,0c-1.3,0.1-2.6,0.2-3.8,0.4c-0.7,0.1-1.5,0.2-2.2,0.3c-0.1,0-0.3,0-0.4,0.1
					c-0.7,0.1-1.5,0.2-2.2,0.4c-0.8,0.1-1.6,0.3-2.3,0.5c0,0,0,0,0,0c-0.8,0.2-1.5,0.3-2.2,0.5c-2.4,0.6-4.7,1.3-6.8,2.2
					c-1.1,0.4-2.2,0.9-3.2,1.3c-1.2,0.5-2.3,1.1-3.4,1.7c-0.3,0.2-0.6,0.3-0.9,0.5c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.8,0.5-1.1,0.7
					c-0.4,0.3-0.8,0.5-1.2,0.8c-0.4,0.3-0.8,0.5-1.2,0.8c-0.3,0.2-0.5,0.4-0.8,0.6c-0.3,0.2-0.6,0.5-0.9,0.7
					c-0.3,0.2-0.6,0.5-0.9,0.7c-0.3,0.3-0.6,0.5-0.9,0.8c-0.3,0.3-0.6,0.6-0.9,0.8c-0.2,0.2-0.5,0.4-0.7,0.6
					c-0.4,0.4-0.8,0.9-1.2,1.3c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.4,0.5-0.6,0.8c-0.5,0.6-1,1.3-1.4,1.9
					c-9.6,13.5-9,30.4-5.3,41.7l0.7,2c3.4,10.3,8,24.5,18.6,41.8c1.3,2.1,2.7,4.3,4.2,6.6c1.8,2.7,3.5,5.1,5.2,7.3
					c0.5,0.7,1,1.3,1.4,1.9c0,0,0.1,0.1,0.1,0.1c0.5,0.7,1,1.3,1.5,2c0.7,0.9,1.3,1.7,2,2.6c0.7,0.9,1.4,1.7,2.1,2.6
					c0.5,0.7,1.1,1.3,1.6,2c0.3,0.3,0.5,0.6,0.8,0.9c0.5,0.6,1.1,1.3,1.7,1.9c0.8,0.9,1.6,1.8,2.4,2.8c0.4,0.5,0.8,0.9,1.2,1.4
					c0.4,0.5,0.8,0.9,1.3,1.4c0.7,0.8,1.4,1.6,2.2,2.3c0.9,0.9,1.8,1.9,2.7,2.8c0.6,0.6,1.2,1.2,1.7,1.8c0,0,0,0,0,0
					c0.5,0.6,1.1,1.2,1.8,1.8c6.9,6.9,18.7,18.2,27.4,25.5c2.5,2.1,5.6,3.3,8.9,3.3c0.9,0,1.7-0.1,2.6-0.3c4.1-0.8,7.6-3.3,9.5-7
					l5.9-11C206.3,210.1,204.5,202.5,198.9,198.5z M188.2,76c1.6,1.5,3,3.1,4.3,4.9c8.5,12,8,27,4.6,37.2l-0.8,2.4
					c-4.3,13.1-10.2,31-27,53.7v0l-0.1-0.1c-1.1-0.9-2.1-1.8-3-2.7c-0.4-0.4-0.8-0.7-1.2-1.1c-0.5-0.4-0.9-0.8-1.3-1.2
					c-1-0.9-1.9-1.7-2.9-2.6c-0.5-0.4-1-0.9-1.4-1.3c-2.4-2.3-4.8-4.7-7.2-7.1C172.1,137,184,116.5,187.8,97
					c0.5-2.6,0.9-5.1,1.1-7.6c0-7.5-2.9-13.7-7.5-18.6C183.8,72.3,186.1,74,188.2,76z M148.7,154.5c-17.5-18.7-28.6-36.7-33.1-53.7
					c-0.2-0.8-0.4-1.7-0.6-2.5c10.3-5.8,21.9-8.9,33.7-8.9c11.9,0,23.5,3.1,33.7,8.9C178.4,116,167.1,134.8,148.7,154.5z
					 M147.8,63.3L147.8,63.3l0.9,0c0.3,0,0.6,0,0.9,0c16.8,0.4,34.2,10.2,34.2,25.9c-0.1,1.3-0.3,2.6-0.5,3.9
					c-10.6-5.7-22.5-8.7-34.7-8.7c-12.2,0-24.1,3-34.7,8.7c-0.2-1.3-0.3-2.5-0.4-3.7C113.6,73.5,131,63.6,147.8,63.3z M198.6,213.8
					l-5.9,11c-1.3,2.3-3.5,4-6.1,4.5c-2.6,0.5-5.3-0.2-7.3-1.9c-7.8-6.5-19.5-17.7-27.1-25.3c-0.6-0.6-1.2-1.2-1.7-1.8
					c-0.8-0.9-1.7-1.7-2.5-2.6c-1-1-1.9-2-2.8-3c-0.6-0.6-1.1-1.2-1.7-1.9c0,0,0,0,0,0c-0.6-0.6-1.1-1.2-1.7-1.9
					c-0.6-0.6-1.1-1.3-1.7-1.9c-0.6-0.6-1.1-1.3-1.6-1.9c-0.5-0.6-1.1-1.3-1.6-1.9v0c-0.6-0.7-1.2-1.5-1.8-2.2
					c-0.6-0.8-1.3-1.5-1.9-2.3c0,0,0,0,0,0c-0.8-1-1.6-2-2.3-3c-0.4-0.5-0.8-1-1.2-1.5c0,0-0.1-0.1-0.1-0.1c-0.5-0.7-1-1.3-1.5-2
					c-1.6-2.1-3.1-4.3-4.8-6.9c-1.5-2.2-2.8-4.4-4.1-6.4c-10.2-16.8-14.8-30.6-18.1-40.7l-0.7-2c-3.2-9.6-3.9-23.8,3.5-35.5
					c0.3-0.6,0.7-1.1,1.1-1.7c2.9-4.1,6.7-7.5,11.3-10.2c-4.7,4.9-7.6,11.2-7.6,18.9c0.2,2,0.4,3.9,0.7,5.8c0.4,2.2,0.8,4.5,1.4,6.7
					c4.7,17.8,16.3,36.7,34.5,56c0.5,0.6,1.1,1.2,1.7,1.8c4.5,4.7,9,9,13.5,13c1.5,1.3,3,2.7,4.6,4.1c0.5,0.4,1,0.9,1.5,1.3
					c0.5,0.5,1.1,0.9,1.6,1.4l0.8,0.7c0.3,0.2,0.6,0.5,0.9,0.7c0.1,0.1,0.2,0.2,0.3,0.3c8.7,7.4,20.5,17.4,25.9,21.1
					C199.6,205.1,200.7,210,198.6,213.8z"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);