import React, { useEffect } from "react";
import axios from "../../axios/axiosBack";
import { Sidebar } from "./Sidebar";
import { MedCategory } from "../MedCategory/MedCategory";
import { Redirect, Route, Switch } from "react-router-dom";
import { MyMeds } from "../MyMeds/MyMeds";
import { Medication } from "../Medication/Medication";
import { Gene } from "../Gene/Gene";
import { Help } from "../Help/Help";
import { Installation } from "../Installation/Installation";
import { Dashboard } from "./Dashboard/Dashboard";
import { useWindowWidth } from "../../hooks/windowWidth";
import { Ref } from "../Ref/Ref";
import { Meds } from "../Meds/Meds";
import CookieBanner from "../CookieBanner/CookieBanner";
import { Cookies } from "../Cookies/Cookies";
import { Terms } from "../Terms/Terms";
import { useSelector } from "react-redux";
import {
  selectHash,
  selectHashIsValid,
  selectTheme,
} from "../../feature/app/appSlice";
import { useCookieConsents } from "@enzsft/react-cookie-consents";

export const Content = () => {
  const { width } = useWindowWidth();
  const hash = useSelector((state) => selectHash(state));
  const hashIsValid = useSelector((state) => selectHashIsValid(state));
  const theme = useSelector((state) => selectTheme(state));
  const cookieConsents = useCookieConsents();

  useEffect(() => {
    (async function fetchData() {
      const response = await axios.get(`/${hash}/consent`);
      if (response?.data[0]?.allowDataCollect === null) {
        cookieConsents.clear();
      }
    })();
  }, []);

  return (
    <>
      <section className={`wrapper ${theme}`}>
        <div>
          <Sidebar />
        </div>
        <div className="content">
          <Switch>
            {!hashIsValid ? <Redirect to={`/${hash}/ups`} /> : null}
            <Route path="/:hash/myMeds" component={MyMeds} />
            <Route exact path="/:hash/help" component={Help} />
            <Route exact path="/:hash/installation" component={Installation} />
            <Route exact path="/:hash/cookies" component={Cookies} />
            <Route exact path="/:hash/terms" component={Terms} />
            <Route exact path="/:hash/ref" component={Ref} />
            <Route exact path="/:hash/medication/:id" component={Medication} />
            <Route path="/:hash/medication/:id/gene/:gene" component={Gene} />
            {/*<Route path="/:hash/meds" component={Meds}/>*/}
            <Route path="/:hash/category/:category" component={MedCategory} />
            {width <= 768 ? (
              <Route exact path="/:hash" component={Dashboard} />
            ) : null}
            <Route path="/:hash">
              {width <= 768 ? (
                <Redirect to={`/${hash}/`} />
              ) : (
                <Redirect to={`/${hash}/myMeds`} />
              )}
            </Route>
          </Switch>
          <CookieBanner />
        </div>
      </section>
    </>
  );
};
