import {combineReducers, configureStore} from "@reduxjs/toolkit";
import userMedicationSlice from '../feature/userMeds/userMedicationSlice'
import medsSlice from '../feature/meds/medsSlice'
import medicationSlice from '../feature/medication/medicationSlice'
import instructionsSlice from '../feature/instructions/instructionsSlice'
import appSlice from "../feature/app/appSlice";

const entities = combineReducers({
    app: appSlice,
    meds: medsSlice,
    medication: medicationSlice,
    userMeds: userMedicationSlice,
    instructions: instructionsSlice,
});

const store = configureStore({
    reducer: {
        entities
    },
});

export default store;