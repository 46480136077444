import {stack as Menu} from "react-burger-menu";
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import config from "../../../config/business/config"
import './navigation.scss';
import {FaBars} from 'react-icons/fa';
import {StateContext} from "../../../context/index";
import Select from 'react-select';
import flag_pt from '../../../assets/img/languages/flag_pt_PT.png'
import flag_br from '../../../assets/img/languages/flag_pt_BR.png'
import flag_es from '../../../assets/img/languages/flag_es_ES.png'
import flag_gb from '../../../assets/img/languages/flag_en_GB.png'
import flag_de from '../../../assets/img/languages/flag_de_DE.png'
import flag_fr from '../../../assets/img/languages/flag_fr_FR.png'
import flag_it from '../../../assets/img/languages/flag_it_IT.png'
import {languageUpdated, selectTheme} from "../../../feature/app/appSlice";
import {connect, useSelector } from "react-redux";
import Text from '../../../assets/strings';


class Navigation extends Component {

    static contextType = StateContext;

    state = {
        menuOpen: false,
        options : [
            {
                value: 'pt_PT',
                flag:"Português",
                label: <div className="language-option">
                    <img src={flag_pt} alt="flag"/>
                    <small>Português</small>
                </div>
            },
            {
                value: 'pt_BR',
                flag:"Português Brasileiro",
                label: <div className="language-option">
                    <img src={flag_br} alt="flag"/>
                    <small>Português do Brasil</small>
                </div>
            },
            {
                value: 'en_GB',
                flag:"English",
                label: <div className="language-option">
                    <img src={flag_gb} alt="flag"/>
                    <small>English</small>
                </div>
            },
            {
                value: 'es_ES',
                flag:"Español",
                label: <div className="language-option">
                    <img src={flag_es} alt="flag"/>
                    <small>Español</small>
                </div>
            },
            {
                value: 'fr_FR',
                flag:"Français",
                label: <div className="language-option">
                    <img src={flag_fr} alt="flag"/>
                    <small>Français</small>
                </div>
            },
            {
                value: 'de_DE',
                flag:"Deutsch",
                label: <div className="language-option">
                    <img src={flag_de} alt="flag"/>
                    <small>Deutsch</small>
                </div>
            },
/*            {
                value: 'it_IT',
                flag:"Italiano",
                label: <div className="language-option">
                    <img src={flag_it} alt="flag"/>
                    <small>Italiano</small>
                </div>
            },*/
        ]
    };
    
    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
        this.setState({menuOpen: false})
    }

    customFilter(option, searchText) {
        return option.data.flag.toLowerCase().includes(searchText.toLowerCase());
    }

    render () {
        const {lang, hash, theme} = this.props.app;
        
        const buttons = config["sidebar-buttons"];

        const valueFromId = (opts, id) => opts.find(o => o.value === id);

        const handleChange = selectedOption => {
            const lang = selectedOption.value;
            this.props.languageUpdated(lang);


            this.closeMenu();

            // window.location.reload();

            // this.props.history.push(this.props.location.pathname);
        };

        return (
            <Menu
            right
            customBurgerIcon={ <FaBars/> }
            disableAutoFocus
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}>
                <div id="language-container">
                    <Select value={valueFromId(this.state.options, lang)}
                            options={this.state.options}
                            onChange={handleChange}
                            filterOption={this.customFilter}/>
                </div>

                {Text[lang].menu.map( b => (
                    <Link className="mobile" key={b.id} to={`/${hash}/${b.id}`} onClick={() => this.closeMenu()}>
                        {b.name}
                    </Link>
                ))}

                <hr className="mobile-divider" />

                <Link to={`/${hash}/help`} onClick={() => this.closeMenu()}>{Text[lang].web.help.title}</Link>
                <Link to={`/${hash}/installation`} onClick={() => this.closeMenu()}>{Text[lang].web.installation.title}</Link>
                <Link to={`/${hash}/ref`} onClick={() => this.closeMenu()}>{Text[lang].web.ref.title}</Link>
                <Link to={`/${hash}/terms`} onClick={() => this.closeMenu()}>{Text[lang].web.terms.title}</Link>
                <Link to={`/${hash}/cookies`} onClick={() => this.closeMenu()}>{Text[lang].web.cookies.title}</Link>
                    {
                        theme !== "hg" && theme !== "invenimus" ?
                            (
                                <ul className="company-content">
                                    <li className="powered-by">
                                        <p><small>Powered by:</small></p>
                                        <img className="header-menu-image"  src={`${process.env.PUBLIC_URL}/img/heartcolor.png`} alt="company logo"/>
                                    </li>
                                    <li className="portugal-2020">
                                        <img className="header-menu-image" src={`${process.env.PUBLIC_URL}/img/2020.png`}  alt="company logo"/>
                                    </li>
                                </ul>
                            )
                        : null
                    }

            </Menu>
        );
    }
}
const mapStateToProps = (state) => {
    const {app} = state.entities;
    return {app}
};

function mapDispatchToProps(dispatch) {
    return {
        languageUpdated: language => {
            dispatch(languageUpdated(language))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navigation));