import {Link} from "react-router-dom";
import React from "react";
import {useStore} from "../../../context";
import Text from '../../../assets/strings';
import {Favorite} from "../icons/Favorite";
import {SideEffects} from "../icons/SideEffects";
import {ListItem} from "./ListItem";
import {AiOutlineWarning} from "react-icons/ai";
import {selectLanguage} from "../../../feature/app/appSlice";
import {useSelector} from 'react-redux'

export const List = (props) => {
    const lang = useSelector(state => selectLanguage(state));
    return (
        <>
            <div className="med-list">
                <ul >
                    <ul className="healthy-med">
                        {props.items?.healthy?.map((drug, i) =>
                            <ListItem {...props} drug={drug} key={i}/>
                        )}
                    </ul>

                    <ul className="warning-med">
                        {props.items?.warning?.map((drug, i) =>
                            <ListItem {...props} drug={drug} key={i}/>
                        )}
                    </ul>

                    <ul className="danger-med">
                        {props.items?.danger?.map((drug, i) =>
                            <ListItem {...props} drug={drug} key={i}/>
                        )}
                    </ul>
                </ul>
            </div>

            <div className="subtitle-container">
                <div className="subtitle">
                    <Favorite size={15}/><sub> - {Text[lang].web.favorite}</sub>
                </div>
                <div>
                    <SideEffects size={15}/><sub> - {Text[lang].web["side-effects"]}</sub>
                </div>
    

            </div>
            <div style={{ color: "#929292", padding: "15px 0"}}>
                <AiOutlineWarning size={15}/><sub style={{fontSize:"11px"}}> <b>{Text[lang].web["warning-bold"]}</b> {Text[lang].web["warning-recommendation"]}</sub>
            </div>
        </>
    );
};